import { Box, Center, Text } from "@chakra-ui/react"

export default function FooterBlack() {
    return <Box w='100%' h='60px' bg='gray.200' >
        <Center>
            <Box w='1000px' >              
                <Text float='left' fontSize={17} mt='15px' fontWeight={400} ml='-10%' >Фабрика Ворот © 2010-2022</Text>
            </Box>
        </Center>

    </Box>
}