import {
    Box,
    Center,
    VStack,
    HStack,
    Text,
    Input,
    Button,
    Icon,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    CloseButton,
    InputGroup,
    InputLeftAddon
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FaTelegram, FaWhatsapp, FaViber } from "react-icons/fa";
import axios from "axios";

export default function Application() {

    const inp1 = document.getElementById("inp1");
    const inp2 = document.getElementById("inp2");

    const [name, setName] = useState('');
    const [number, setNumber] = useState('');

    function sendM(x) {
        x.preventDefault();
        console.log(name, number)

        let state = true;

        if (name === '' || number === '' || number.length < 10) {
            document.getElementById('errorModMobile').style.display = 'block';
            state = false;
            document.body.style.overflow = 'hidden'
        }

        if (state === true) {
            axios.post("https://fabrika-vorot.site:49522/data", {
                firstName: name,
                number: number
            });

            const modal = document.getElementById("modMobile");
            modal.style.display = 'block';
            document.body.style.overflow = 'hidden';
        }
    };


    function close() {
        const modal = document.getElementById("modMobile");

        document.body.style.overflow = '';
        modal.style.display = 'none';
        inp1.value = '';
        inp2.value = '';
    }

    function closeError() {
        const modalEr = document.getElementById("errorModMobile");
        modalEr.style.display = 'none';
        document.body.style.overflow = '';
        document.getElementById("1").value = '';
        document.getElementById("2").value = '';
        document.getElementById("3").value = '';
    }

    useEffect(() => {
        const appliBut = document.getElementById("appliBut");
        const appliText = document.getElementById("appliText");
        appliBut.onmouseover = () => {
            appliBut.style.background = 'black';
            appliText.style.color = 'white';
        }
        appliBut.onmouseleave = () => {
            appliBut.style.background = 'white';
            appliText.style.color = "black";
        }
    }, []);

    return <Box w='100%' h='1080px' id='application' >
        <Center>
            <Box w='90%' h='850px'  >
                <Center>
                    <hr style={{
                        color: '#1974ff',
                        backgroundColor: '#1974ff',
                        height: .8,
                        borderColor: '#1974ff',
                        width: 60,
                        marginTop: 80,
                    }} />
                </Center>
                <Text textAlign='center' fontSize={30} mt='17px' fontFamily='SportsWorld' >Оформить заявку</Text>
                <Center>
                    <Text color='gray' textAlign='center' fontSize={19} w='700px' mt='5px' fontFamily='RobotoRegular' >
                        Не забудьте указать свой номер телефона, мы Вам перезвоним и договоримся о выезде специалиста. В блоке "Сообщение" Вы можете указать пожелания, требования и все необходимы детали.</Text>
                </Center>
                <Box w='100%' h='450px' mt='40px' ml='0%' >
                    <Box w='100%' h='450px'>
                        <VStack gap={10}>
                            <Box w='100' h='60px' >
                                <Text color='gray' fontSize={19} fontWeight={300} fontFamily='RobotoRegular' >Имя</Text>
                                <Input
                                    w='335px'
                                    h='55px'
                                    borderRadius={0}
                                    mt='10px'
                                    id='inp1'
                                    onChange={x => setName(x.target.value)}
                                />
                            </Box>
                            <Box w='100' h='60px' >
                                <Text color='gray' fontSize={19} fontWeight={300} fontFamily='RobotoRegular' >Телефон</Text>
                                <InputGroup>
                                    <InputLeftAddon children='+7' h='55px' mt='10px' />
                                    <Input
                                        w='283px'
                                        h='55px'
                                        borderRadius={0}
                                        mt='10px'
                                        id='inp2'
                                        placeholder="(987) 654-32-10"
                                        onChange={x => setNumber(x.target.value)}
                                    />
                                </InputGroup>
                            </Box>
                        </VStack>
                        <Center>
                            <Button
                                variant='outline'
                                colorScheme='white'
                                borderRadius={0}
                                background='white'
                                w='200px'
                                h='60px'
                                mt='60px'
                                ml='3px'
                                border='solid 1px black'
                                id='appliBut'
                                onClick={sendM}
                            ><Text color='black' fontWeight={400} fontSize={18} id='appliText' >Оставить заявку</Text>
                            </Button>
                        </Center>
                        <Alert
                            status='success'
                            variant='subtle'
                            flexDirection='column'
                            justifyContent='center'
                            textAlign='center'
                            height='200px'
                            style={{ display: 'none' }}
                            id='modMobile'
                            w='330px'
                            h='205px'
                            pos='fixed'
                            bottom='0'
                            top='0'
                            left='0'
                            right='0'
                            zIndex={3}
                            ml='11%'
                            mt='50%'
                        >
                            <Center>
                                <AlertIcon boxSize='40px' mr={0} />
                            </Center>
                            <AlertTitle mt={4} mb={1} fontSize='lg'>
                                Заявка успешно оформлена!
                            </AlertTitle>
                            <AlertDescription maxWidth='sm'>
                                Наш оператор перезвонит Вам в течению 30 минут. Если все операторы заняты, то время ожидания может увеличиться.
                            </AlertDescription>
                            <CloseButton
                                alignSelf='flex-start'
                                float='right'
                                mr='-15px'
                                mt='-165px'
                                onClick={close}
                            />
                        </Alert>

                        <Alert
                            status='error'
                            variant='subtle'
                            flexDirection='column'
                            justifyContent='center'
                            textAlign='center'
                            height='200px'
                            style={{ display: 'none' }}
                            id='errorModMobile'
                            w='330px'
                            h='250px'
                            pos='fixed'
                            bottom='0'
                            top='0'
                            left='0'
                            right='0'
                            zIndex={3}
                            ml='11%'
                            mt='50%'
                        >
                            <Center>
                                <AlertIcon boxSize='40px' mr={0} />
                            </Center>
                            <AlertTitle mt={4} mb={1} fontSize='lg'>
                                Форма заявки неправильно заполнена!
                            </AlertTitle>
                            <AlertDescription maxWidth='sm'>
                                Проверьте правильно ли вы ввели имя и номер телефона(Номер телефона должен быть без кода страны). Перепроверьте данные и заполните форму снова.
                            </AlertDescription>
                            <CloseButton
                                alignSelf='flex-start'
                                float='right'
                                mr='-15px'
                                mt='-210px'
                                onClick={closeError}
                            />
                        </Alert>

                    </Box>

                    <Box w='100%' h='450px' mt='-120px' id='contact' >
                        <Box>
                            <Box w='100%' h='60px' >
                                <Text color='gray' fontSize={21} fontWeight={300} fontFamily='RobotoRegular' >Почта</Text>
                                <a href='mailto:fabrika.vorot@mail.ru'
                                    onClick={() => {
                                        window.ym(90042661, 'reachGoal', 'clicked_on_email')
                                        console.log('Цель метрики на почту');
                                    }} ><Text color='black' className='big' fontSize={22} fontWeight={300} >fabrika.vorot@mail.ru</Text></a>
                            </Box>
                            <Box w='100%' h='60px' mt='15px'>
                                <Text color='gray' fontSize={21} fontWeight={300} fontFamily='RobotoRegular' >Телефон</Text>
                                <Text color='black' fontSize={22} fontWeight={300} className='big' ><a href='tel:+79773395353'
                                    onClick={() => {
                                        window.ym(90042661, 'reachGoal', 'clicked_on_phone')
                                        console.log('Цель метрики на нажатие номера выполнена');
                                    }}
                                >+7 (977) 339-53-53</a></Text>
                            </Box>
                            <Box w='100%' h='60px' mt='15px'>
                                <Text color='gray' fontSize={21} fontWeight={300} fontFamily='RobotoRegular' >Мессенджеры</Text>
                                <HStack ml='0px' mt='7px' >
                                    <a href="tg://resolve?domain=fabrikaVorot" className='big'
                                        onClick={() => {
                                            window.ym(90042661, 'reachGoal', 'clicked_on_messenger_telegram')
                                            console.log('Цель метрики на Telegram');
                                        }}><Icon as={FaTelegram} w='35px' h='35px' /></a>
                                    <a href="https://wa.me/+79257096009" className='big'
                                        onClick={() => {
                                            window.ym(90042661, 'reachGoal', 'clicked_on_messenger_whatsapp')
                                            console.log('Цель метрики на WhatsApp');
                                        }} ><Icon as={FaWhatsapp} w='35px' h='35px' /></a>
                                    <a href="https://viber.click/79254333444" className='big'
                                        onClick={() => {
                                            window.ym(90042661, 'reachGoal', 'clicked_on_messenger_viber')
                                            console.log('Цель метрики на Viber');
                                        }}><Icon as={FaViber} w='35px' h='35px' /></a>
                                </HStack>
                            </Box>
                            <Box w='100%' h='60px' mt='25px'>
                                <Text color='gray' fontSize={21} fontWeight={300} fontFamily='RobotoRegular' >Адрес</Text>
                                <Text color='black' fontSize={22} fontWeight={300} >город Красногорск, < br />ул. Ильинский тупик, 1, корп. 2</Text>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Center >

    </Box >
}