import {
    Box,
    Center,
    HStack,
    SimpleGrid,
    Text
} from "@chakra-ui/react";
import { Fade } from 'react-reveal';

export default function Advantages() {
    return <Box w='100%' h='1640px' >
        <Center>
            <Box w='90%' h='1200px' id='advantages' >
                <Center>
                    <hr style={{
                        color: '#1974ff',
                        backgroundColor: '#1974ff',
                        height: .8,
                        borderColor: '#1974ff',
                        width: 60,
                        marginTop: 20,
                    }} />
                </Center>
                <Text textAlign='center' fontSize={30} mt='5px' fontFamily='Cocomat'  >Почему <span style={{ fontWeight: "700", fontFamily: 'SportsWorld' }} >"Фабрика Ворот"</span>?</Text>
                <Center>
                    <Box w='90%' h='1080px' mt='20px'>

                        <SimpleGrid columns={1}>
                            <Fade right ><Box h='350px' w='100%' >
                                <Center>
                                    <HStack gap={3} ml='0px' >
                                        <Text fontSize={60} mt='-3px' fontFamily='SportsWorld'>1</Text>
                                        <Text fontSize={25} fontWeight={500} ml='0px' color='gray.600' fontFamily='RobotoRegular' >Полимерная покраска</Text>
                                    </HStack>
                                </Center>
                                <Center>
                                    <Text fontSize={21} w='100%' mt='-5px' fontFamily='Cocomat' >Лакокрасочные материалы – не единственный вариант окрашивания, наша компания предоставляет порошковое окрашивание ворот. Основные достоинства: долговечность покрытия, эстетичность, высокое качество.
                                    </Text>
                                </Center>
                            </Box></Fade>
                            <Fade left><Box h='370px' w='100%'>
                                <HStack gap={3} ml='0px' mt='20px' >
                                    <Text fontSize={60} mt='-4px' fontFamily='SportsWorld'>
                                        2
                                    </Text>
                                    <Text fontSize={30} fontWeight={500} ml='0px' color='gray.600' fontFamily='RobotoRegular'  >Профессиональная бригада</Text>
                                </HStack>
                                <Center>
                                    <Text fontSize={22} w='100%' mt='10px' fontFamily='Cocomat' >В нашей команде ра<span className='b'>б</span>отают только настоящие профессионалы со стажем <span className='b'>б</span>олее <span className='b'>10</span> лет. <span className='b'>Б</span>ригада специалистов выполнит всю ра<span className='b'>б</span>оту <span className='b'>б</span>ыстро, качественно и строго в о<span className='b'>б</span>говоренные сроки.
                                    </Text>
                                </Center>
                            </Box></Fade>
                            <Fade right><Box h='330px' w='100%' mt='20px'>
                                <HStack gap={3} ml='0px' >
                                    <Text fontSize={60} mt='-4px' fontFamily='SportsWorld'>
                                        3
                                    </Text>

                                    <Text fontSize={30} fontWeight={500} ml='20px' color='gray.600' fontFamily='RobotoRegular' >Без "подводных камней"</Text>
                                </HStack>
                                <Center>
                                    <Text fontSize={22} w='100%' mt='10px' fontFamily='Cocomat' >Вы можете <span className='b'>б</span>ыть уверены, что стоимость всех ра<span className='b'>б</span>от, которая <span className='b'>б</span>ыла о<span className='b'>б</span>говоренна изначально, не <span className='b'>б</span>удет изменяться на протяжение выполнения всего заказа.
                                    </Text>
                                </Center>
                            </Box></Fade>
                            <Fade left><Box h='350px' w='100%'>
                                <HStack gap={3} ml='0px' >
                                    <Text fontSize={60} mt='-4px' fontFamily='SportsWorld'>4</Text>
                                    <Text fontSize={35} fontWeight={500} ml='20px' color='gray.600' fontFamily='RobotoRegular' >Работа без предоплаты</Text>
                                </HStack>
                                <Center>
                                    <Text fontSize={22} w='100%' mt='10px' fontFamily='Cocomat' >Наша компания ра<span className='b'>б</span>отает <span className='b'>б</span>ез предоплаты. Оплата производится только после выполненной ра<span className='b'>б</span>оты и удовлетворения потре<span className='b'>б</span>ностей клиента связаных с его заказом.
                                    </Text>
                                </Center>
                            </Box></Fade>
                        </SimpleGrid>
                    </Box>
                </Center>
            </Box >
        </Center >
    </Box >
}