import { Box, Button, Center, HStack, Image, Icon } from "@chakra-ui/react";
import { Link } from "react-scroll";
import { FaTelegram, FaWhatsapp } from 'react-icons/fa';

export default function Bar() {

    return <Center>
        <Box w='95%' maxW='1300px' zIndex={2} pos='relative' h='100px'>
            <Image w='200px' mt='-53px' src={require('../../../../img/logo.png')} />

            <Box
                float='right'
                w='60%'
                h='30px'
                mr='0%'
                mt='-130px'
                zIndex={3}
                pos='relative'
            >
                <HStack gap={5} mt='0px' >
                    <Button variant='link' color='white' fontSize={21} fontWeight={400} fontFamily='RobotoRegular' >
                        <Link to='catalog' smooth={true} >Каталог</Link>
                    </Button>
                    <Button variant='link' color='white' fontSize={21} fontWeight={400} fontFamily='RobotoRegular' >
                        <Link to='calculator' smooth={true} >Калькулятор</Link>
                    </Button>
                    <Button variant='link' color='white' fontSize={21} fontWeight={400} fontFamily='RobotoRegular'>
                        <Link to='aboutUs' smooth={true} >О компании</Link>
                    </Button>
                    <Button variant='link' color='white' fontSize={21} fontWeight={400} fontFamily='RobotoRegular'>
                        <Link to='application' smooth={true}>Контакты</Link></Button>
                    <Button variant='link' color='white' fontSize={21} fontWeight={400} ><a href='tel:+79257096009' 
                    onClick={() => {
                        window.ym(90042661, 'reachGoal', 'clicked_on_phone')
                        console.log('Цель метрики на нажатие номера выполнена');
                    }} fontFamily='RobotoRegular'  >+7 (925) 709-60-09</a></Button>
                    <HStack mt='7px'>
                        <a href="tg://resolve?domain=fabrikaVorot" onClick={() => {
                        window.ym(90042661, 'reachGoal', 'clicked_on_messenger_telegram')
                        console.log('Цель метрики на Telegram');
                    }} className='big'><Icon as={FaTelegram} w='35px' h='35px' color='white' mb='-7px' /></a>
                        <a href="https://wa.me/+79257096009" onClick={() => {
                        window.ym(90042661, 'reachGoal', 'clicked_on_messenger_whatsapp')
                        console.log('Цель метрики на WhatsApp');
                    }} className='big' ><Icon as={FaWhatsapp} w='35px' h='35px' color='white' mb='-7px' /></a>
                    </HStack>
                </HStack>
            </Box>
        </Box>
    </Center >
}