import { Box, Text, Button } from "@chakra-ui/react";
import { useEffect } from "react";
import { Link } from "react-scroll";

export default function Description() {

    useEffect(() => {
        const ordercall = document.getElementById("orderCall");
        ordercall.onmouseover = () => ordercall.style.color = '#1C1C1C';
        ordercall.onmouseleave = () => ordercall.style.color = "white";
    }, []);

    return <Box
        w='670px'
        h='300px'
        pos='absolute'
        zIndex={3}
        ml={window.innerWidth > 1200 ? '45.5%' : '35%'}
        mt='90px'
        p={3}
    >
        <Text color='#FFFFFF' textAlign='left' fontSize={37} fontWeight={400} fontFamily='SportsWorld' >Производство и установка</Text>
        <Text color='#FFFFFF' textAlign='left' fontSize={30} fontWeight={300} fontFamily='Cocomat' >
            Откатных и распашных ворот лю<span className="b">б</span>ой сложности по Москве и Московской о<span className="b">б</span>ласти</Text>
        <Button
            variant='outline'
            colorScheme='gray'
            borderRadius={0}
            color='#FFFFFF'
            w='233px'
            h='65px'
            fontSize={20}
            mt='40px'
            ml='3px'
            border='solid 1px'
            id='orderCall'
        ><Link to='application' smooth={true} >Заказать звонок</Link></Button>
    </Box>
}