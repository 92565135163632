import { Box, HStack } from "@chakra-ui/react";
import { FaTelegram, FaWhatsapp, FaViber } from 'react-icons/fa';
import { Icon, HamburgerIcon } from "@chakra-ui/icons";
import { IconButton } from "@chakra-ui/react";
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem
} from '@chakra-ui/react';
import { Link } from "react-scroll";



export default function BarMobile() {
    return <Box w='100%' h='60px' bg='gray.900' mt='50px' pos='fixed' zIndex={4} top="10px" >
        <Menu>
            <MenuButton
                as={IconButton}
                aria-label='Options'
                icon={<HamburgerIcon />}
                variant='outline'
                bg='#FFFFFF'
                float='left'
                mt='10px'
                ml='10px'
                pos='relative'
                zIndex={3}
            />
            <MenuList pos='relative' zIndex={3} >
                <MenuItem >
                    <Link to='ourWork' smooth={true} >Главная</Link>
                </MenuItem>
                <MenuItem >
                    <Link to='catalog' smooth={true} >Каталог</Link>
                </MenuItem>
                <MenuItem >
                    <Link to='calculator' smooth={true} >Калькулятор</Link>
                </MenuItem>
                <MenuItem >
                    <Link to='aboutUs' smooth={true} >О компании</Link>
                </MenuItem>
                <MenuItem >
                    <Link to='ourWork' smooth={true} >Наши работы</Link>
                </MenuItem>
                <MenuItem >
                    <Link to='contact' smooth={true} >Контакты</Link>
                </MenuItem>
                <MenuItem >
                    <Link to='application' smooth={true}>Оставить заявку</Link>
                </MenuItem>
                <MenuItem >
                    <HStack>
                        <a href="tg://resolve?domain=fabrikaVorot" className='big'
                            onClick={() => {
                                window.ym(90042661, 'reachGoal', 'clicked_on_messenger_telegram')
                                console.log('Цель метрики на нажатие Телеграм');
                            }} ><Icon as={FaTelegram} w='35px' h='35px' /></a>

                        <a href="https://wa.me/+79254333333" className='big'
                            onClick={() => {
                                window.ym(90042661, 'reachGoal', 'clicked_on_messenger_whatsapp')
                                console.log('Цель метрики на нажатие WhatsApp');
                            }}><Icon as={FaWhatsapp} w='35px' h='35px' /></a>
                        <a href="https://viber.click/79254333444" className='big'
                            onClick={() => {
                                window.ym(90042661, 'reachGoal', 'clicked_on_messenger_viber')
                                console.log('Цель метрики на нажатие Viber');
                            }} ><Icon as={FaViber} w='35px' h='35px' /></a>
                    </HStack>
                </MenuItem>
            </MenuList>
        </Menu>

    </Box>
}