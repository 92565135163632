import { Box } from "@chakra-ui/react";

const back = require('../../../img/background.jpeg');

export default function Header() {
    return <Box
        w='100%'
        bg='blue'
        h='500px'
        backgroundImage={back}
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
        bgSize='280%'
        className="mainBackMobile"
        zIndex={1}
        pos='absolute'
        mt='120px'
    >
    </Box>
}