import { Box } from "@chakra-ui/react";
const back = require('../../../img/background.jpeg');

export default function Bg(){
    return <Box
    w='100%'
    bg='blue'
    h='400px'
    backgroundImage={back}
    backgroundPosition="center"
    backgroundRepeat="no-repeat"
    bgSize='110%'
    className="mainBack"
    zIndex={1}
    pos='absolute'
>
</Box>
}