import { Box, Image, Center, Text } from "@chakra-ui/react"

export default function FooterBlack() {
    return <Box w='100%' h='100px' bg='gray.200' >
        <Center>
            <Box w='1000px' >
                <Box float='right'>
                    <Image src={require('../../../img/AIYlogo.png')} />
                    <Text w='100px' mt='-72px' ml='90px' >Придумано и сделано</Text>
                </Box>
                <Text fontSize={17} mt='26px' fontWeight={400} ml='5%' >Фабрика Ворот © 2010-2022</Text>
            </Box>
        </Center>

    </Box>
}