import { Box, Center, Text, Button } from "@chakra-ui/react";
import { useEffect } from "react";
import { Link } from "react-scroll";

export default function Child() {

    useEffect(() => {
        const freeTrip = document.getElementById("freeTrip");
        freeTrip.onmouseover = () => freeTrip.style.color = '#1C1C1C';
        freeTrip.onmouseleave = () => freeTrip.style.color = "white";
    }, []);

    return <Center>
        <Box w='100%' h='250px' zIndex={2} pos='absolute' mt='370px' id='freeTrip' >
            <Center>
                <Text color='#FFFFFF' fontSize={27} fontweidth={700} mt='-10px' fontFamily='SportsWorld' >Бесплатный замер</Text>
            </Center>
            <Center>
                <Text color='#FFFFFF' textAlign='center' fontSize={17} w='90%' fontweidth={300} mt='10px' fontFamily='RobotoRegular' >Наша компания предоставляет бесплатный выезд специалиста</Text>
            </Center>
            <Center>
                <Link to='application' smooth={true} > <Button
                    variant='outline'
                    colorScheme='gray'
                    borderRadius={0}
                    color='#FFFFFF'
                    w='233px'
                    h='65px'
                    fontSize={20}
                    mt='40px'
                    ml='3px'
                    border='solid 1px'
                    id='freeTrip'
                >Оставить заявку</Button>
                </Link>
            </Center>
        </Box>
    </Center>
}