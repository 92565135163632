import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import LaptopApp from './LaptopApp';
import MobileApp from './MobileApp';
import { ChakraProvider } from '@chakra-ui/react';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(window.innerWidth > 1000 ? <ChakraProvider>
    <LaptopApp />
  </ChakraProvider> : <ChakraProvider>
    <MobileApp />
  </ChakraProvider> 
);

