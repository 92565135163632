import {
    Box,
    Center,
    Text,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,

    TableContainer,
} from "@chakra-ui/react";

export default function TableBlock() {
    return <Box h='1100px' w='100%'>
        <Center>
            <Box h='1100px' w='90%' minW='1000px' maxW='1200px' id='tableCost' >

                <hr style={{
                    color: '#1974ff',
                    backgroundColor: '#1974ff',
                    height: .8,
                    borderColor: '#1974ff',
                    width: 55,
                    marginTop: 10,
                }} />

                <Text color='#1C1C1C' fontSize={35} fontWeight={400} mt='10px' ml='0px' fontFamily='Cocomat' >Цены на каркасы <span className="textTable">откатных</span> ворот</Text>

                <TableContainer mt='30px' >
                    <Table variant='striped' colorScheme='teal'>
                        <Thead>
                            <Tr>
                                <Th fontWeight={700} fontcolor='black' >Высота</Th>
                                <Th fontWeight={700} fontcolor='black' >Ширина</Th>
                                <Th></Th>
                                <Th></Th>
                                <Th></Th>
                                <Th></Th>
                                <Th></Th>
                                <Th></Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            <Tr>
                                <Td></Td>
                                <Td fontWeight={700} >3200</Td>
                                <Td fontWeight={700} >3700</Td>
                                <Td fontWeight={700} >4200</Td>
                                <Td fontWeight={700} >4700</Td>
                                <Td fontWeight={700} >5200</Td>
                                <Td fontWeight={700} >5700</Td>
                                <Td fontWeight={700} >6200</Td>
                            </Tr>
                            <Tr>
                                <Td fontWeight={700} >1900</Td>
                                <Td>28000</Td>
                                <Td>29000</Td>
                                <Td>30000</Td>
                                <Td>33000</Td>
                                <Td>35000</Td>
                                <Td>58000</Td>
                                <Td>60000</Td>
                            </Tr>
                            <Tr>
                                <Td fontWeight={700} >2000</Td>
                                <Td>28000</Td>
                                <Td>29000</Td>
                                <Td>30000</Td>
                                <Td>33000</Td>
                                <Td>35000</Td>
                                <Td>58000</Td>
                                <Td>60000</Td>
                            </Tr>
                            <Tr>
                                <Td fontWeight={700} >2150</Td>
                                <Td>28000</Td>
                                <Td>29000</Td>
                                <Td>30000</Td>
                                <Td>33000</Td>
                                <Td>35000</Td>
                                <Td>58000</Td>
                                <Td>60000</Td>
                            </Tr>
                            <Tr>
                                <Td fontWeight={700} >2500</Td>
                                <Td>32000</Td>
                                <Td>34000</Td>
                                <Td>36000</Td>
                                <Td>39000</Td>
                                <Td>40000</Td>
                                <Td>65000</Td>
                                <Td>68000</Td>
                            </Tr>
                            <Tr>
                                <Td fontWeight={700} >2700</Td>
                                <Td>35000</Td>
                                <Td>36500</Td>
                                <Td>37500</Td>
                                <Td>42000</Td>
                                <Td>43000</Td>
                                <Td>69000</Td>
                                <Td>72000</Td>
                            </Tr>
                            <Tr>
                                <Td fontWeight={700} >3000</Td>
                                <Td>37000</Td>
                                <Td>39000</Td>
                                <Td>39500</Td>
                                <Td>44000</Td>
                                <Td>46000</Td>
                                <Td>75000</Td>
                                <Td>78000</Td>
                            </Tr>
                        </Tbody>
                    </Table>
                </TableContainer>

                <hr style={{
                    color: '#1974ff',
                    backgroundColor: '#1974ff',
                    height: .8,
                    borderColor: '#1974ff',
                    width: 55,
                    marginTop: 80,
                }} />

                <Text color='#1C1C1C' fontSize={35} fontWeight={400} mt='10px' ml='0px' fontFamily='Cocomat' >Цены на каркасы <span className="textTable" >распашных</span> ворот</Text>
                <TableContainer mt='30px' >
                    <Table variant='striped' colorScheme='teal'>
                        <Thead>
                            <Tr>
                                <Th fontWeight={700} fontcolor='black' >Высота</Th>
                                <Th fontWeight={700} fontcolor='black' >Ширина</Th>
                                <Th></Th>
                                <Th></Th>
                                <Th></Th>
                                <Th></Th>
                                <Th></Th>
                                <Th></Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            <Tr>
                                <Td></Td>
                                <Td fontWeight={700} >3000</Td>
                                <Td fontWeight={700} >3500</Td>
                                <Td fontWeight={700} >4000</Td>
                                <Td fontWeight={700} >4500</Td>
                                <Td fontWeight={700} >5000</Td>
                                <Td fontWeight={700} >5500</Td>
                                <Td fontWeight={700} >6000</Td>
                            </Tr>
                            <Tr>
                                <Td fontWeight={700} >1800</Td>
                                <Td>25000</Td>
                                <Td>27000</Td>
                                <Td>28000</Td>
                                <Td>29000</Td>
                                <Td>32000</Td>
                                <Td>35000</Td>
                                <Td>39000</Td>
                            </Tr>
                            <Tr>
                                <Td fontWeight={700} >2000</Td>
                                <Td>25000</Td>
                                <Td>27000</Td>
                                <Td>28000</Td>
                                <Td>29000</Td>
                                <Td>32000</Td>
                                <Td>35000</Td>
                                <Td>39000</Td>
                            </Tr>
                            <Tr>
                                <Td fontWeight={700} >2200</Td>
                                <Td>26000</Td>
                                <Td>27000</Td>
                                <Td>28000</Td>
                                <Td>29000</Td>
                                <Td>32000</Td>
                                <Td>35000</Td>
                                <Td>39000</Td>
                            </Tr>
                            <Tr>
                                <Td fontWeight={700} >2500</Td>
                                <Td>27000</Td>
                                <Td>27000</Td>
                                <Td>29500</Td>
                                <Td>30500</Td>
                                <Td>32000</Td>
                                <Td>36000</Td>
                                <Td>42000</Td>
                            </Tr>
                            <Tr>
                                <Td fontWeight={700} >2700</Td>
                                <Td>29000</Td>
                                <Td>30500</Td>
                                <Td>32500</Td>
                                <Td>33500</Td>
                                <Td>34500</Td>
                                <Td>37500</Td>
                                <Td>44000</Td>
                            </Tr>
                            <Tr>
                                <Td fontWeight={700} >3000</Td>
                                <Td>30000</Td>
                                <Td>32500</Td>
                                <Td>34500</Td>
                                <Td>35000</Td>
                                <Td>37000</Td>
                                <Td>39000</Td>
                                <Td>46000</Td>
                            </Tr>
                        </Tbody>
                    </Table>
                </TableContainer>
            </Box>
        </Center>
    </Box>
}