import {
    Box,
    Center,
    GridItem,
    SimpleGrid,
    Text,
    Image,
    HStack,
    Button,
    Input,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    Icon,
    VStack,
    InputGroup,
    InputLeftAddon
} from '@chakra-ui/react';
import { useState } from 'react';
import { TbTruckDelivery } from "react-icons/tb";
import { FcBusinessman } from 'react-icons/fc';
import React from 'react';
import axios from 'axios';

export default function Catalog() {

    const [name, setName] = useState('');
    const [number, setNumber] = useState('');
    const [weight, setWeight] = useState('');
    const [height, setHeight] = useState('');

    function sendM(x) {
        x.preventDefault();
        console.log(name, number)

        let state = true;

        if (name === '' || number === '' || number.length < 10) {
            document.getElementById('errorModMobile').style.display = 'block';
            state = false;
            document.body.style.overflow = 'hidden'
        }

        if (state === true) {
            axios.post("https://fabrika-vorot.site:49522/data", {
                firstName: name,
                number: number,
                height: height,
                weight: weight
            });

            const modal = document.getElementById("modMobile");
            modal.style.display = 'block';
            document.body.style.overflow = 'hidden';
        }
    };

    const inp1 = document.getElementById("inp1");
    const inp1_2 = document.getElementById("inp1.2");

    const inp2 = document.getElementById("inp2");
    const inp2_2 = document.getElementById("inp2.2");

    const inp3 = document.getElementById("inp3");
    const inp3_2 = document.getElementById("inp3.2");

    const inp4 = document.getElementById("inp4");
    const inp4_2 = document.getElementById("inp4.2");

    const inp5 = document.getElementById("inp5");
    const inp5_2 = document.getElementById("inp5.2");

    const inp6 = document.getElementById("inp6");
    const inp6_2 = document.getElementById("inp6.2");

    const inp7 = document.getElementById("inp7");
    const inp7_2 = document.getElementById("inp7.2");

    const inp8 = document.getElementById("inp8");
    const inp8_2 = document.getElementById("inp8.2");

    const inp9 = document.getElementById("inp9");
    const inp9_2 = document.getElementById("inp9.2");

    let sliding = [];
    let swing = [];

    const cost = [
        sliding = [
            '27 900',
            '32 900',
            '42 900',
            '37 900'
        ],
        swing = [
            '14 900',
            '36 900',
            '20 900',
            '23 900',
            '27 900'
        ]
    ]

    const [saleBlockM, setsaleBlockM] = React.useState({
        id: 'bbb',
        display: 'none',
        width: '100%',
        height: '100%',
        margin: 'auto',
        bottom: 0,
        top: 0,
        left: 0,
        right: 0,
        background: '#FFFFFF',
        position: 'fixed',
        zIndex: '1',
        transition: '1s',
        padding: '30px',
        overflow: 'scroll'
    });

    const [saleBlockM2, setsaleBlockM2] = React.useState({
        id: 'bbb',
        display: 'none',
        width: '100%',
        height: '100%',
        margin: 'auto',
        bottom: 0,
        top: 0,
        left: 0,
        right: 0,
        background: '#FFFFFF',
        position: 'fixed',
        zIndex: '1',
        transition: '1s',
        padding: '30px',
        overflow: 'scroll'
    });
    const [saleBlockM3, setsaleBlockM3] = React.useState({
        id: 'bbb',
        display: 'none',
        width: '100%',
        height: '100%',
        margin: 'auto',
        bottom: 0,
        top: 0,
        left: 0,
        right: 0,
        background: '#FFFFFF',
        position: 'fixed',
        zIndex: '1',
        transition: '1s',
        padding: '30px',
        overflow: 'scroll'
    });
    const [saleBlockM4, setsaleBlockM4] = React.useState({
        id: 'bbb',
        display: 'none',
        width: '100%',
        height: '100%',
        margin: 'auto',
        bottom: 0,
        top: 0,
        left: 0,
        right: 0,
        background: '#FFFFFF',
        position: 'fixed',
        zIndex: '1',
        transition: '1s',
        padding: '30px',
        overflow: 'scroll'
    });
    const [saleBlockM5, setsaleBlockM5] = React.useState({
        id: 'bbb',
        display: 'none',
        width: '100%',
        height: '100%',
        margin: 'auto',
        bottom: 0,
        top: 0,
        left: 0,
        right: 0,
        background: '#FFFFFF',
        position: 'fixed',
        zIndex: '1',
        transition: '1s',
        padding: '30px',
        overflow: 'scroll'
    });
    const [saleBlockM6, setsaleBlockM6] = React.useState({
        id: 'bbb',
        display: 'none',
        width: '100%',
        height: '100%',
        margin: 'auto',
        bottom: 0,
        top: 0,
        left: 0,
        right: 0,
        background: '#FFFFFF',
        position: 'fixed',
        zIndex: '1',
        transition: '1s',
        padding: '30px',
        overflow: 'scroll'
    });
    const [saleBlockM7, setsaleBlockM7] = React.useState({
        id: 'bbb',
        display: 'none',
        width: '100%',
        height: '100%',
        margin: 'auto',
        bottom: 0,
        top: 0,
        left: 0,
        right: 0,
        background: '#FFFFFF',
        position: 'fixed',
        zIndex: '1',
        transition: '1s',
        padding: '30px',
        overflow: 'scroll'
    });
    const [saleBlockM8, setsaleBlockM8] = React.useState({
        id: 'bbb',
        display: 'none',
        width: '100%',
        height: '100%',
        margin: 'auto',
        bottom: 0,
        top: 0,
        left: 0,
        right: 0,
        background: '#FFFFFF',
        position: 'fixed',
        zIndex: '1',
        transition: '1s',
        padding: '30px',
        overflow: 'scroll'
    });
    const [saleBlockM9, setsaleBlockM9] = React.useState({
        id: 'bbb',
        display: 'none',
        width: '100%',
        height: '100%',
        margin: 'auto',
        bottom: 0,
        top: 0,
        left: 0,
        right: 0,
        background: '#FFFFFF',
        position: 'fixed',
        zIndex: '1',
        transition: '1s',
        padding: '30px',
        overflow: 'scroll'
    });

    const [crossM, setCrossM] = React.useState({
        position: 'fixed',
        bottom: 0,
        top: 0,
        left: 0,
        right: 0,
        margin: '3.4% 0 0 89%',
        zIndex: 2,
        display: 'none'
    });
    const [crossM2, setCrossM2] = React.useState({
        position: 'fixed',
        bottom: 0,
        top: 0,
        left: 0,
        right: 0,
        margin: '3.4% 0 0 89%',
        zIndex: 2,
        display: 'none'
    });
    const [crossM3, setCrossM3] = React.useState({
        position: 'fixed',
        bottom: 0,
        top: 0,
        left: 0,
        right: 0,
        margin: '3.4% 0 0 89%',
        zIndex: 2,
        display: 'none'
    });
    const [crossM4, setCrossM4] = React.useState({
        position: 'fixed',
        bottom: 0,
        top: 0,
        left: 0,
        right: 0,
        margin: '3.4% 0 0 89%',
        zIndex: 2,
        display: 'none'
    });
    const [crossM5, setCrossM5] = React.useState({
        position: 'fixed',
        bottom: 0,
        top: 0,
        left: 0,
        right: 0,
        margin: '3.4% 0 0 89%',
        zIndex: 2,
        display: 'none'
    });
    const [crossM6, setCrossM6] = React.useState({
        position: 'fixed',
        bottom: 0,
        top: 0,
        left: 0,
        right: 0,
        margin: '3.4% 0 0 89%',
        zIndex: 2,
        display: 'none'
    });
    const [crossM7, setCrossM7] = React.useState({
        position: 'fixed',
        bottom: 0,
        top: 0,
        left: 0,
        right: 0,
        margin: '3.4% 0 0 89%',
        zIndex: 2,
        display: 'none'
    });
    const [crossM8, setCrossM8] = React.useState({
        position: 'fixed',
        bottom: 0,
        top: 0,
        left: 0,
        right: 0,
        margin: '3.4% 0 0 89%',
        zIndex: 2,
        display: 'none'
    });
    const [crossM9, setCrossM9] = React.useState({
        position: 'fixed',
        bottom: 0,
        top: 0,
        left: 0,
        right: 0,
        margin: '3.4% 0 0 89%',
        zIndex: 2,
        display: 'none'
    });

    console.log(sliding, swing);

    return <Box w='100%' h='3900px' id='catalog'>
        <Center>
            <Box w='90%' h='1400px'>
                <hr style={{
                    color: '#1974ff',
                    backgroundColor: '#1974ff',
                    height: .8,
                    borderColor: '#1974ff',
                    width: 50,
                    marginTop: -10,
                    marginLeft: 15
                }} />
                <Text color='#1C1C1C' fontSize={40} fontWeight={400} mt='2px' ml='15px' fontFamily='SportsWorld' >Каталог</Text>
                <Center>
                    <SimpleGrid columns={1} gap={5} mt='20px' >
                        <GridItem>
                            <Box
                                w={window.innerWidth > 1200 ? '360px' : '300px'}
                                h='400px'
                                border="solid 1px gray"
                                borderRadius={5}
                                py={3}
                                px={5}
                            >
                                <Text fontWeight={700} fontSize={19} fontFamily='RobotoRegular' >Откатные ворота без зашивки</Text>
                                <Image
                                    src={require('../../../img/otkatnie/1.png')}
                                    w='300px'
                                    h='150px'
                                    mt='20px'
                                />
                                <Text float='right' color='gray' mt='5px' textDecoration='line-through' >32 900₽</Text>
                                <HStack gap='96px' mt='33px' >
                                    <Text fontSize={19}>Цена от:</Text>
                                    <Text fontSize={21} fontWeight={700} fontFamily='RobotoRegular'>27 900₽</Text>
                                </HStack>
                                <Center>
                                    <Button
                                        variant='solid'
                                        colorScheme='blue'
                                        borderRadius={0}
                                        w='300px'
                                        h='65px'
                                        fontSize={20}
                                        mt='15px'
                                        ml='3px'
                                        border='solid 1px'
                                        onClick={() => {
                                            setsaleBlockM(prev => ({ ...prev, display: "block" }));
                                            setCrossM(prev => ({ ...prev, display: "block" }));
                                        }}
                                    >Подробнее</Button>
                                </Center>
                            </Box>
                        </GridItem>

                        <GridItem>
                            <Box
                                w={window.innerWidth > 1200 ? '360px' : '300px'}
                                h='400px'
                                border="solid 1px gray"
                                borderRadius={5}
                                py={3}
                                px={5}
                            >
                                <Text fontWeight={700} fontSize={19} fontFamily='RobotoRegular' >Откатные ворота из профлиста</Text>
                                <Image
                                    src={require('../../../img/otkatnie/3.png')}
                                    w='300px'
                                    h='150px'
                                    mt='20px'
                                />
                                <Text float='right' color='gray' mt='5px' textDecoration='line-through' >41 000₽</Text>
                                <HStack gap='107px' mt='33px' >
                                    <Text>Цена от:</Text>
                                    <Text fontSize={21} fontWeight={700} fontFamily='RobotoRegular'>32 900₽</Text>
                                </HStack>
                                <Center>
                                    <Button
                                        variant='solid'
                                        colorScheme='blue'
                                        borderRadius={0}
                                        w='300px'
                                        h='65px'
                                        fontSize={20}
                                        mt='15px'
                                        ml='3px'
                                        border='solid 1px'
                                        onClick={() => {
                                            setsaleBlockM2(prev => ({ ...prev, display: "block" }));
                                            setCrossM2(prev => ({ ...prev, display: "block" }));
                                        }}
                                    >Подробнее</Button>
                                </Center>
                            </Box>
                        </GridItem>
                        <GridItem>
                            <Box
                                w={window.innerWidth > 1200 ? '360px' : '300px'}
                                h='400px'
                                border="solid 1px gray"
                                borderRadius={5}
                                py={3}
                                px={5}
                            >
                                <Text fontWeight={700} fontSize={19} fontFamily='RobotoRegular' >Откатные ворота решётчатые</Text>
                                <Image
                                    src={require('../../../img/otkatnie/2.png')}
                                    w='250px'
                                    h='150px'
                                    mt='20px'
                                />
                                <Text float='right' color='gray' mt='5px' textDecoration='line-through' >51 000₽</Text>
                                <HStack gap='107px' mt='33px' >
                                    <Text>Цена от:</Text>
                                    <Text fontSize={21} fontWeight={700} fontFamily='RobotoRegular'>42 900₽</Text>
                                </HStack>
                                <Center>
                                    <Button
                                        variant='solid'
                                        colorScheme='blue'
                                        borderRadius={0}
                                        w='300px'
                                        h='65px'
                                        fontSize={20}
                                        mt='15px'
                                        ml='3px'
                                        border='solid 1px'
                                        onClick={() => {
                                            setsaleBlockM3(prev => ({ ...prev, display: "block" }));
                                            setCrossM3(prev => ({ ...prev, display: "block" }));
                                        }}
                                    >Подробнее</Button>
                                </Center>
                            </Box>
                        </GridItem>
                        <GridItem>
                            <Box
                                w={window.innerWidth > 1200 ? '360px' : '300px'}
                                h='400px'
                                border="solid 1px gray"
                                borderRadius={5}
                                py={3}
                                px={5}
                            >
                                <Text fontWeight={700} fontSize={19} fontFamily='RobotoRegular' >Откатные ворота из штакетника</Text>
                                <Image
                                    src={require('../../../img/otkatnie/4.png')}
                                    w='300px'
                                    h='150px'
                                    mt='20px'
                                />
                                <Text float='right' color='gray' mt='5px' textDecoration='line-through' >45 000₽</Text>
                                <HStack gap='107px' mt='33px' >
                                    <Text>Цена от:</Text>
                                    <Text fontSize={21} fontWeight={700} fontFamily='RobotoRegular'>37 900₽</Text>
                                </HStack>
                                <Center>
                                    <Button
                                        variant='solid'
                                        colorScheme='blue'
                                        borderRadius={0}
                                        w='300px'
                                        h='65px'
                                        fontSize={20}
                                        mt='15px'
                                        ml='3px'
                                        border='solid 1px'
                                        onClick={() => {
                                            setsaleBlockM4(prev => ({ ...prev, display: "block" }));
                                            setCrossM4(prev => ({ ...prev, display: "block" }));
                                        }}
                                    >Подробнее</Button>
                                </Center>
                            </Box>
                        </GridItem>
                        <GridItem>
                            <Box
                                w={window.innerWidth > 1200 ? '360px' : '300px'}
                                h='400px'
                                border="solid 1px gray"
                                borderRadius={5}
                                py={3}
                                px={5}
                            >
                                <Text fontWeight={700} fontSize={19} fontFamily='RobotoRegular' >Распашные ворота без зашивки</Text>
                                <Image
                                    src={require('../../../img/otkatnie/11.png')}
                                    w='300px'
                                    h='150px'
                                    mt='20px'
                                />
                                <Text float='right' color='gray' mt='5px' textDecoration='line-through' >20 000₽</Text>
                                <HStack gap='107px' mt='33px' >
                                    <Text>Цена от:</Text>
                                    <Text fontSize={21} fontWeight={700} fontFamily='RobotoRegular'>14 900₽</Text>
                                </HStack>
                                <Center>
                                    <Button
                                        variant='solid'
                                        colorScheme='blue'
                                        borderRadius={0}
                                        w='300px'
                                        h='65px'
                                        fontSize={20}
                                        mt='15px'
                                        ml='3px'
                                        border='solid 1px'
                                        onClick={() => {
                                            setsaleBlockM5(prev => ({ ...prev, display: "block" }));
                                            setCrossM5(prev => ({ ...prev, display: "block" }));
                                        }}
                                    >Подробнее</Button>
                                </Center>
                            </Box>
                        </GridItem>
                        <GridItem>
                            <Box
                                w={window.innerWidth > 1200 ? '360px' : '300px'}
                                h='400px'
                                border="solid 1px gray"
                                borderRadius={5}
                                py={3}
                                px={5}
                            >
                                <Text fontWeight={700} fontSize={19} fontFamily='RobotoRegular' >Распашные ворота из профлиста</Text>
                                <Image
                                    src={require('../../../img/otkatnie/12.png')}
                                    w='300px'
                                    h='150px'
                                    mt='20px'
                                />
                                <Text float='right' color='gray' mt='5px' textDecoration='line-through' >42 000₽</Text>
                                <HStack gap='107px' mt='33px' >
                                    <Text>Цена от:</Text>
                                    <Text fontSize={21} fontWeight={700} fontFamily='RobotoRegular'>36 900₽</Text>
                                </HStack>
                                <Center>
                                    <Button
                                        variant='solid'
                                        colorScheme='blue'
                                        borderRadius={0}
                                        w='300px'
                                        h='65px'
                                        fontSize={20}
                                        mt='15px'
                                        ml='3px'
                                        border='solid 1px'
                                        onClick={() => {
                                            setsaleBlockM6(prev => ({ ...prev, display: "block" }));
                                            setCrossM6(prev => ({ ...prev, display: "block" }));
                                        }}
                                    >Подробнее</Button>
                                </Center>
                            </Box>
                        </GridItem>
                        <GridItem>
                            <Box
                                w={window.innerWidth > 1200 ? '360px' : '300px'}
                                h='400px'
                                border="solid 1px gray"
                                borderRadius={5}
                                py={3}
                                px={5}
                            >
                                <Text fontWeight={700} fontSize={19} fontFamily='RobotoRegular' >Распашные ворота из штакетника</Text>
                                <Image
                                    src={require('../../../img/otkatnie/13.png')}
                                    w='300px'
                                    h='150px'
                                    mt='20px'
                                />
                                <Text float='right' color='gray' mt='5px' textDecoration='line-through' >28 000₽</Text>
                                <HStack gap='107px' mt='33px' >
                                    <Text>Цена от:</Text>
                                    <Text fontSize={21} fontWeight={700} fontFamily='RobotoRegular'>20 900₽</Text>
                                </HStack>
                                <Center>
                                    <Button
                                        variant='solid'
                                        colorScheme='blue'
                                        borderRadius={0}
                                        w='300px'
                                        h='65px'
                                        fontSize={20}
                                        mt='15px'
                                        ml='3px'
                                        border='solid 1px'
                                        onClick={() => {
                                            setsaleBlockM7(prev => ({ ...prev, display: "block" }));
                                            setCrossM7(prev => ({ ...prev, display: "block" }));
                                            document.body.style.overflow = 'hidden';
                                        }}
                                    >Подробнее</Button>
                                </Center>
                            </Box>
                        </GridItem>
                        <GridItem>
                            <Box
                                w={window.innerWidth > 1200 ? '360px' : '300px'}
                                h='400px'
                                border="solid 1px gray"
                                borderRadius={5}
                                py={3}
                                px={5}
                            >
                                <Text fontWeight={700} fontSize={19} fontFamily='RobotoRegular' >Распашные ворота из поликарбоната</Text>
                                <Image
                                    src={require('../../../img/otkatnie/14.png')}
                                    w='300px'
                                    h='150px'
                                    mt='20px'
                                />
                                <Text float='right' color='gray' mt='5px' textDecoration='line-through' >30 000₽</Text>
                                <HStack gap='107px' mt='33px' >
                                    <Text>Цена от:</Text>
                                    <Text fontSize={21} fontWeight={700} fontFamily='RobotoRegular'>23 900₽</Text>
                                </HStack>
                                <Center>
                                    <Button
                                        variant='solid'
                                        colorScheme='blue'
                                        borderRadius={0}
                                        w='300px'
                                        h='65px'
                                        fontSize={20}
                                        mt='15px'
                                        ml='3px'
                                        border='solid 1px'
                                        onClick={() => {
                                            setsaleBlockM8(prev => ({ ...prev, display: "block" }));
                                            setCrossM8(prev => ({ ...prev, display: "block" }));
                                        }}
                                    >Подробнее</Button>
                                </Center>
                            </Box>
                        </GridItem>
                        <GridItem>
                            <Box
                                w={window.innerWidth > 1200 ? '360px' : '300px'}
                                h='400px'
                                border="solid 1px gray"
                                borderRadius={5}
                                py={3}
                                px={5}
                            >
                                <Text fontWeight={700} fontSize={19} fontFamily='RobotoRegular' >Распашные ворота из 3D сетки</Text>
                                <Image
                                    src={require('../../../img/otkatnie/15.png')}
                                    w='300px'
                                    h='150px'
                                    mt='20px'
                                />
                                <Text float='right' color='gray' mt='5px' textDecoration='line-through' >32 900₽</Text>
                                <HStack gap='107px' mt='33px' >
                                    <Text>Цена от:</Text>
                                    <Text fontSize={21} fontWeight={700} fontFamily='RobotoRegular'>27 900₽</Text>
                                </HStack>
                                <Center>
                                    <Button
                                        variant='solid'
                                        colorScheme='blue'
                                        borderRadius={0}
                                        w='300px'
                                        h='65px'
                                        fontSize={20}
                                        mt='15px'
                                        ml='3px'
                                        border='solid 1px'
                                        onClick={() => {
                                            setsaleBlockM9(prev => ({ ...prev, display: "block" }));
                                            setCrossM9(prev => ({ ...prev, display: "block" }));
                                        }}
                                    >Подробнее</Button>
                                </Center>
                            </Box>
                        </GridItem>
                    </SimpleGrid>
                </Center>
            </Box>
        </Center>


        {/*ОТКРЫВАЮЩИЙСЯ БЛОК 1 */}

        <Box style={saleBlockM} className='sss' >
            <Text fontFamily='RobotoRegular' fontSize={29} textAlign='center' mt='30px' mr='0px' >Откатные ворота без зашивки</Text>
            <Center>
                <Image
                    src={require('../../../img/otkatnie/1.png')}
                    margin='15px 0 0 0px'
                    w='450px'
                    h='175px'
                />
            </Center>
            <Text
                textAlign='left'
                w='95%'
                fontFamily='RobotoLight'
                mt='30px'
                fontSize='17pt'
                pos='relative'
            >Откатные ворота выполены из жесткого металлического каркаса.
                При изготовлении используется профильные трубы 60x40, 40х20 2мм. После изготовления наши ворота отправляются на полимерную покраску.</Text>
            <VStack m='0px 0 0 0px' gap={8} >
                <Box w='100%' h='60px' >
                    <Center>
                        <Text mt='10px' fontSize={22} fontWeight={600} fontFamily='Cocomat' >Имя</Text>
                    </Center>
                    <Input
                        id='inp1'
                        w='100%'
                        h='45px'
                        borderRadius={0}
                        mt='10px'
                        onChange={x => setName(x.target.value)} />
                </Box>
                <Box w='100%' h='60px' >
                    <Center>
                        <Text mt='10px' fontSize={22} fontWeight={600} fontFamily='Cocomat' >Телефон</Text>
                    </Center>
                    <InputGroup>
                        <InputLeftAddon children='+7' h='45px' mt='10px' />
                        <Input
                            w='303px'
                            h='45px'
                            borderRadius={0}
                            mt='10px'
                            id='inp1.2'
                            maxLength={10}
                            placeholder="(987) 654-32-10"
                            onChange={x => setNumber(x.target.value)}
                        />
                    </InputGroup>
                </Box>
                <Box mt='20px' ml='80px' mr='0px' w='100%' >
                    <Center><Text mt='10px' fontSize={22} fontWeight={600} fontFamily='Cocomat' >Ширина ворот</Text></Center>
                    <NumberInput
                        step={100}
                        defaultValue={3000}
                        min={3000}
                        max={6200}
                        w='100%'
                        mt='10px'
                        size='lg'
                        ml='0px'
                        onChange={newWeight => { setWeight(newWeight) }}
                    >
                        <NumberInputField />
                        <Box float='right' w='55px' h='46px' bg='#e9ecef' mt='-46.5px' mr='25px' zIndex={2} p={2} >
                            <Text fontWeight={600} ml='7px' mt='2.5px' >MM</Text>
                        </Box>
                        <NumberInputStepper mt='0px'>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                        </NumberInputStepper>
                    </NumberInput>
                </Box>
                <Box mt='0px' ml='80px' w='100%'>
                    <Center>
                        <Text mt='-30px' fontSize={22} fontWeight={600} fontFamily='Cocomat' >
                            Высота ворот</Text>
                    </Center>
                    <NumberInput
                        step={100}
                        defaultValue={1800}
                        min={1800}
                        max={3000}
                        w='100%'
                        mt='10px'
                        size='lg'
                        ml='0px'
                        onChange={newHeight => { setHeight(newHeight) }}
                    >
                        <NumberInputField />
                        <Box float='right' w='55px' h='46px' bg='#e9ecef' mt='-46.5px' mr='25px' zIndex={2} p={2} >
                            <Text fontWeight={600} ml='7px' mt='2.5px' >MM</Text>
                        </Box>
                        <NumberInputStepper mt='0px'>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                        </NumberInputStepper>
                    </NumberInput>
                </Box>
                <Button
                    variant='solid'
                    colorScheme='blue'
                    borderRadius={0}
                    w='100%'
                    h='65px'
                    fontSize={20}
                    mt='-10px'
                    ml='3px'
                    border='solid 1px'
                    onClick={sendM}
                >Расчитать</Button>
            </VStack>
            <HStack gap='20px' mt='20px' >
                <Text fontSize={20} fontFamily="RobotoRegular" >Цена от:</Text>
                <Text fontSize={35} fontWeight={700} fontFamily='RobotoRegular'>{cost[0][0]}₽ <span className="cost" >35 000 ₽</span></Text>
            </HStack>
            <HStack w='100%' mt='20px' >
                <Box w='50%'>
                    <HStack>
                        <Icon as={FcBusinessman} w='40px' h='40px' />
                        <Text fontSize={14} >Выездной менеджер бесплатно</Text>
                    </HStack>
                </Box>
                <Box w='50'>
                    <HStack>
                        <Icon as={TbTruckDelivery} w='40px' h='40px' />
                        <Text fontSize={14}>Доставка до 20км от МКАД в подарок </Text>
                    </HStack>
                </Box>
            </HStack>
        </Box>
        <Button
            background='none'
            border='none'
            cursor='pointer'
            overflow='hidden'
            outline='none'
            w='1px'
            h='1px'
            onClick={() => {
                setsaleBlockM(prev => ({ ...prev, display: "none" }));
                setCrossM(prev => ({ ...prev, display: "none" }));
                document.body.style.overflow = "";
                inp1.value = '';
                inp1_2.value = '';
            }}  >
            <img
                alt=''
                src={require('../../../img/cross-svgrepo-com.svg').default}
                style={crossM} />
        </Button>




        {/*ОТКРЫВАЮЩИЙСЯ БЛОК 2 */}

        <Box style={saleBlockM2} className='sss' >
            <Text fontFamily='RobotoRegular' fontSize={29} textAlign='center' mt='30px' mr='0px' >Откатные ворота из профлиста</Text>
            <Center>
                <Image
                    src={require('../../../img/otkatnie/3.png')}
                    margin='15px 0 0 0px'
                    w='450px'
                    h='175px'
                />
            </Center>
            <Text
                textAlign='left'
                w='95%'
                fontFamily='RobotoLight'
                mt='30px'
                fontSize='17pt'
                pos='relative'
            >Откатные ворота выполены из жесткого металлического каркаса.
                При изготовлении используется профильные трубы 60x40, 40х20 2мм. После изготовления наши ворота отправляются на полимерную покраску.</Text>
            <VStack m='0px 0 0 0px' gap={8} >
                <Box w='100%' h='60px' >
                    <Center>
                        <Text mt='10px' fontSize={22} fontWeight={600} fontFamily='Cocomat' >Имя</Text>
                    </Center>
                    <Input
                        id='inp2'
                        w='100%'
                        h='45px'
                        borderRadius={0}
                        mt='10px'
                        onChange={x => setName(x.target.value)} />
                </Box>
                <Box w='100%' h='60px' >
                    <Center>
                        <Text mt='10px' fontSize={22} fontWeight={600} fontFamily='Cocomat' >Телефон</Text>
                    </Center>
                    <InputGroup>
                        <InputLeftAddon children='+7' h='45px' mt='10px' />
                        <Input
                            w='303px'
                            h='45px'
                            borderRadius={0}
                            mt='10px'
                            id='inp2.2'
                            maxLength={10}
                            placeholder="(987) 654-32-10"
                            onChange={x => setNumber(x.target.value)}
                        />
                    </InputGroup>
                </Box>
                <Box mt='20px' ml='80px' mr='0px' w='100%' >
                    <Center><Text mt='10px' fontSize={22} fontWeight={600} fontFamily='Cocomat' >Ширина ворот</Text></Center>
                    <NumberInput
                        step={100}
                        defaultValue={3000}
                        min={3000}
                        max={6200}
                        w='100%'
                        mt='10px'
                        size='lg'
                        ml='0px'
                        onChange={newWeight => { setWeight(newWeight) }}
                    >
                        <NumberInputField />
                        <Box float='right' w='55px' h='46px' bg='#e9ecef' mt='-46.5px' mr='25px' zIndex={2} p={2} >
                            <Text fontWeight={600} ml='7px' mt='2.5px' >MM</Text>
                        </Box>
                        <NumberInputStepper mt='0px'>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                        </NumberInputStepper>
                    </NumberInput>
                </Box>
                <Box mt='0px' ml='80px' w='100%'>
                    <Center>
                        <Text mt='-30px' fontSize={22} fontWeight={600} fontFamily='Cocomat' >
                            Высота ворот</Text>
                    </Center>
                    <NumberInput
                        step={100}
                        defaultValue={1800}
                        min={1800}
                        max={3000}
                        w='100%'
                        mt='10px'
                        size='lg'
                        ml='0px'
                        onChange={newHeight => { setHeight(newHeight) }}
                    >
                        <NumberInputField />
                        <Box float='right' w='55px' h='46px' bg='#e9ecef' mt='-46.5px' mr='25px' zIndex={2} p={2} >
                            <Text fontWeight={600} ml='7px' mt='2.5px' >MM</Text>
                        </Box>
                        <NumberInputStepper mt='0px'>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                        </NumberInputStepper>
                    </NumberInput>
                </Box>
                <Button
                    variant='solid'
                    colorScheme='blue'
                    borderRadius={0}
                    w='100%'
                    h='65px'
                    fontSize={20}
                    mt='-10px'
                    ml='3px'
                    border='solid 1px'
                    onClick={sendM}
                >Расчитать</Button>
            </VStack>
            <HStack gap='20px' mt='20px' >
                <Text fontSize={20} fontFamily="RobotoRegular" >Цена от:</Text>
                <Text fontSize={35} fontWeight={700} fontFamily='RobotoRegular'>{cost[0][1]}₽ <span className="cost" >35 000 ₽</span></Text>
            </HStack>
            <HStack w='100%' mt='20px' >
                <Box w='50%'>
                    <HStack>
                        <Icon as={FcBusinessman} w='40px' h='40px' />
                        <Text fontSize={14} >Выездной менеджер бесплатно</Text>
                    </HStack>
                </Box>
                <Box w='50'>
                    <HStack>
                        <Icon as={TbTruckDelivery} w='40px' h='40px' />
                        <Text fontSize={14}>Доставка до 20км от МКАД в подарок </Text>
                    </HStack>
                </Box>
            </HStack>
        </Box>
        <Button
            background='none'
            border='none'
            cursor='pointer'
            overflow='hidden'
            outline='none'
            w='1px'
            h='1px'
            onClick={() => {
                setsaleBlockM2(prev => ({ ...prev, display: "none" }));
                setCrossM2(prev => ({ ...prev, display: "none" }));
                inp2.value = '';
                inp2_2.value = '';
            }}  >
            <img
                alt=''
                src={require('../../../img/cross-svgrepo-com.svg').default}
                style={crossM2} />
        </Button>






        {/*ОТКРЫВАЮЩИЙСЯ БЛОК 3 */}

        <Box style={saleBlockM3} className='sss' >
            <Text fontFamily='RobotoRegular' fontSize={29} textAlign='center' mt='30px' mr='0px' >Откатные ворота решётчатые</Text>
            <Center>
                <Image
                    src={require('../../../img/otkatnie/2.png')}
                    margin='15px 0 0 0px'
                    w='450px'
                    h='175px'
                />
            </Center>
            <Text
                textAlign='left'
                w='95%'
                fontFamily='RobotoLight'
                mt='30px'
                fontSize='17pt'
                pos='relative'
            >Откатные ворота выполены из жесткого металлического каркаса.
                При изготовлении используется профильные трубы 60x40, 40х20 2мм. После изготовления наши ворота отправляются на полимерную покраску.</Text>
            <VStack m='0px 0 0 0px' gap={8} >
                <Box w='100%' h='60px' >
                    <Center>
                        <Text mt='10px' fontSize={22} fontWeight={600} fontFamily='Cocomat' >Имя</Text>
                    </Center>
                    <Input
                        id='inp3'
                        w='100%'
                        h='45px'
                        borderRadius={0}
                        mt='10px'
                        onChange={x => setName(x.target.value)} />
                </Box>
                <Box w='100%' h='60px' >
                    <Center>
                        <Text mt='10px' fontSize={22} fontWeight={600} fontFamily='Cocomat' >Телефон</Text>
                    </Center>
                    <InputGroup>
                        <InputLeftAddon children='+7' h='45px' mt='10px' />
                        <Input
                            w='303px'
                            h='45px'
                            borderRadius={0}
                            mt='10px'
                            id='inp3.2'
                            maxLength={10}
                            placeholder="(987) 654-32-10"
                            onChange={x => setNumber(x.target.value)}
                        />
                    </InputGroup>
                </Box>
                <Box mt='20px' ml='80px' mr='0px' w='100%' >
                    <Center><Text mt='10px' fontSize={22} fontWeight={600} fontFamily='Cocomat' >Ширина ворот</Text></Center>
                    <NumberInput
                        step={100}
                        defaultValue={3000}
                        min={3000}
                        max={6200}
                        w='100%'
                        mt='10px'
                        size='lg'
                        ml='0px'
                        onChange={newWeight => { setWeight(newWeight) }}
                    >
                        <NumberInputField />
                        <Box float='right' w='55px' h='46px' bg='#e9ecef' mt='-46.5px' mr='25px' zIndex={2} p={2} >
                            <Text fontWeight={600} ml='7px' mt='2.5px' >MM</Text>
                        </Box>
                        <NumberInputStepper mt='0px'>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                        </NumberInputStepper>
                    </NumberInput>
                </Box>
                <Box mt='0px' ml='80px' w='100%'>
                    <Center>
                        <Text mt='-30px' fontSize={22} fontWeight={600} fontFamily='Cocomat' >
                            Высота ворот</Text>
                    </Center>
                    <NumberInput
                        step={100}
                        defaultValue={1800}
                        min={1800}
                        max={3000}
                        w='100%'
                        mt='10px'
                        size='lg'
                        ml='0px'
                        onChange={newHeight => { setHeight(newHeight) }}
                    >
                        <NumberInputField />
                        <Box float='right' w='55px' h='46px' bg='#e9ecef' mt='-46.5px' mr='25px' zIndex={2} p={2} >
                            <Text fontWeight={600} ml='7px' mt='2.5px' >MM</Text>
                        </Box>
                        <NumberInputStepper mt='0px'>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                        </NumberInputStepper>
                    </NumberInput>
                </Box>
                <Button
                    variant='solid'
                    colorScheme='blue'
                    borderRadius={0}
                    w='100%'
                    h='65px'
                    fontSize={20}
                    mt='-10px'
                    ml='3px'
                    border='solid 1px'
                    onClick={sendM}
                >Расчитать</Button>
            </VStack>
            <HStack gap='20px' mt='20px' >
                <Text fontSize={20} fontFamily="RobotoRegular" >Цена от:</Text>
                <Text fontSize={35} fontWeight={700} fontFamily='RobotoRegular'>{cost[0][2]}₽ <span className="cost" >35 000 ₽</span></Text>
            </HStack>
            <HStack w='100%' mt='20px' >
                <Box w='50%'>
                    <HStack>
                        <Icon as={FcBusinessman} w='40px' h='40px' />
                        <Text fontSize={14} >Выездной менеджер бесплатно</Text>
                    </HStack>
                </Box>
                <Box w='50'>
                    <HStack>
                        <Icon as={TbTruckDelivery} w='40px' h='40px' />
                        <Text fontSize={14}>Доставка до 20км от МКАД в подарок </Text>
                    </HStack>
                </Box>
            </HStack>
        </Box>
        <Button
            background='none'
            border='none'
            cursor='pointer'
            overflow='hidden'
            outline='none'
            w='1px'
            h='1px'
            onClick={() => {
                setsaleBlockM3(prev => ({ ...prev, display: "none" }));
                setCrossM3(prev => ({ ...prev, display: "none" }));
                inp3.value = '';
                inp3_2.value = '';
            }}  >
            <img
                alt=''
                src={require('../../../img/cross-svgrepo-com.svg').default}
                style={crossM3} />
        </Button>



        {/*ОТКРЫВАЮЩИЙСЯ БЛОК 4 */}

        <Box style={saleBlockM4} className='sss' >
            <Text fontFamily='RobotoRegular' fontSize={29} textAlign='center' mt='30px' mr='0px' >Откатные ворота из поликарбоната</Text>
            <Center>
                <Image
                    src={require('../../../img/otkatnie/4.png')}
                    margin='15px 0 0 0px'
                    w='450px'
                    h='175px'
                />
            </Center>
            <Text
                textAlign='left'
                w='95%'
                fontFamily='RobotoLight'
                mt='30px'
                fontSize='17pt'
                pos='relative'
            >Откатные ворота выполены из жесткого металлического каркаса.
                При изготовлении используется профильные трубы 60x40, 40х20 2мм. После изготовления наши ворота отправляются на полимерную покраску.</Text>
            <VStack m='0px 0 0 0px' gap={8} >
                <Box w='100%' h='60px' >
                    <Center>
                        <Text mt='10px' fontSize={22} fontWeight={600} fontFamily='Cocomat' >Имя</Text>
                    </Center>
                    <Input
                        id='inp4'
                        w='100%'
                        h='45px'
                        borderRadius={0}
                        mt='10px'
                        onChange={x => setName(x.target.value)} />
                </Box>
                <Box w='100%' h='60px' >
                    <Center>
                        <Text mt='10px' fontSize={22} fontWeight={600} fontFamily='Cocomat' >Телефон</Text>
                    </Center>
                    <InputGroup>
                        <InputLeftAddon children='+7' h='45px' mt='10px' />
                        <Input
                            w='303px'
                            h='45px'
                            borderRadius={0}
                            mt='10px'
                            id='inp4.2'
                            maxLength={10}
                            placeholder="(987) 654-32-10"
                            onChange={x => setNumber(x.target.value)}
                        />
                    </InputGroup>
                </Box>
                <Box mt='20px' ml='80px' mr='0px' w='100%' >
                    <Center><Text mt='10px' fontSize={22} fontWeight={600} fontFamily='Cocomat' >Ширина ворот</Text></Center>
                    <NumberInput
                        step={100}
                        defaultValue={3000}
                        min={3000}
                        max={6200}
                        w='100%'
                        mt='10px'
                        size='lg'
                        ml='0px'
                        onChange={newWeight => { setWeight(newWeight) }}
                    >
                        <NumberInputField />
                        <Box float='right' w='55px' h='46px' bg='#e9ecef' mt='-46.5px' mr='25px' zIndex={2} p={2} >
                            <Text fontWeight={600} ml='7px' mt='2.5px' >MM</Text>
                        </Box>
                        <NumberInputStepper mt='0px'>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                        </NumberInputStepper>
                    </NumberInput>
                </Box>
                <Box mt='0px' ml='80px' w='100%'>
                    <Center>
                        <Text mt='-30px' fontSize={22} fontWeight={600} fontFamily='Cocomat' >
                            Высота ворот</Text>
                    </Center>
                    <NumberInput
                        step={100}
                        defaultValue={1800}
                        min={1800}
                        max={3000}
                        w='100%'
                        mt='10px'
                        size='lg'
                        ml='0px'
                        onChange={newHeight => { setHeight(newHeight) }}
                    >
                        <NumberInputField />
                        <Box float='right' w='55px' h='46px' bg='#e9ecef' mt='-46.5px' mr='25px' zIndex={2} p={2} >
                            <Text fontWeight={600} ml='7px' mt='2.5px' >MM</Text>
                        </Box>
                        <NumberInputStepper mt='0px'>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                        </NumberInputStepper>
                    </NumberInput>
                </Box>
                <Button
                    variant='solid'
                    colorScheme='blue'
                    borderRadius={0}
                    w='100%'
                    h='65px'
                    fontSize={20}
                    mt='-10px'
                    ml='3px'
                    border='solid 1px'
                    onClick={sendM}
                >Расчитать</Button>
            </VStack>
            <HStack gap='20px' mt='20px' >
                <Text fontSize={20} fontFamily="RobotoRegular" >Цена от:</Text>
                <Text fontSize={35} fontWeight={700} fontFamily='RobotoRegular'>{cost[0][3]}₽ <span className="cost" >35 000 ₽</span></Text>
            </HStack>
            <HStack w='100%' mt='20px' >
                <Box w='50%'>
                    <HStack>
                        <Icon as={FcBusinessman} w='40px' h='40px' />
                        <Text fontSize={14} >Выездной менеджер бесплатно</Text>
                    </HStack>
                </Box>
                <Box w='50'>
                    <HStack>
                        <Icon as={TbTruckDelivery} w='40px' h='40px' />
                        <Text fontSize={14}>Доставка до 20км от МКАД в подарок </Text>
                    </HStack>
                </Box>
            </HStack>
        </Box>
        <Button
            background='none'
            border='none'
            cursor='pointer'
            overflow='hidden'
            outline='none'
            w='1px'
            h='1px'
            onClick={() => {
                setsaleBlockM4(prev => ({ ...prev, display: "none" }));
                setCrossM4(prev => ({ ...prev, display: "none" }));
                inp4.value = '';
                inp4_2.value = '';
            }}  >
            <img
                alt=''
                src={require('../../../img/cross-svgrepo-com.svg').default}
                style={crossM4} />
        </Button>



        {/*ОТКРЫВАЮЩИЙСЯ БЛОК 5 */}

        <Box style={saleBlockM5} className='sss' >
            <Text fontFamily='RobotoRegular' fontSize={29} textAlign='center' mt='30px' mr='0px' >Распашные ворота без зашивки</Text>
            <Center>
                <Image
                    src={require('../../../img/otkatnie/11.png')}
                    margin='15px 0 0 0px'
                    w='450px'
                    h='175px'
                />
            </Center>
            <Text
                textAlign='left'
                w='95%'
                fontFamily='RobotoLight'
                mt='30px'
                fontSize='17pt'
                pos='relative'
            >Откатные ворота выполены из жесткого металлического каркаса.
                При изготовлении используется профильные трубы 60x40, 40х20 2мм. После изготовления наши ворота отправляются на полимерную покраску.</Text>
            <VStack m='0px 0 0 0px' gap={8} >
                <Box w='100%' h='60px' >
                    <Center>
                        <Text mt='10px' fontSize={22} fontWeight={600} fontFamily='Cocomat' >Имя</Text>
                    </Center>
                    <Input id='inp5' w='100%' h='45px' borderRadius={0} mt='10px' onChange={x => setName(x.target.value)} />
                </Box>
                <Box w='100%' h='60px' >
                    <Center>
                        <Text mt='10px' fontSize={22} fontWeight={600} fontFamily='Cocomat' >Телефон</Text>
                    </Center>
                    <InputGroup>
                        <InputLeftAddon children='+7' h='45px' mt='10px' />
                        <Input
                            w='303px'
                            h='45px'
                            borderRadius={0}
                            mt='10px'
                            id='inp5.2'
                            maxLength={10}
                            placeholder="(987) 654-32-10"
                            onChange={x => setNumber(x.target.value)}
                        />
                    </InputGroup>
                </Box>
                <Box mt='20px' ml='80px' mr='0px' w='100%' >
                    <Center><Text mt='10px' fontSize={22} fontWeight={600} fontFamily='Cocomat' >Ширина ворот</Text></Center>
                    <NumberInput
                        step={100}
                        defaultValue={3000}
                        min={3000}
                        max={6200}
                        w='100%'
                        mt='10px'
                        size='lg'
                        ml='0px'
                        onChange={newWeight => { setWeight(newWeight) }}
                    >
                        <NumberInputField />
                        <Box float='right' w='55px' h='46px' bg='#e9ecef' mt='-46.5px' mr='25px' zIndex={2} p={2} >
                            <Text fontWeight={600} ml='7px' mt='2.5px' >MM</Text>
                        </Box>
                        <NumberInputStepper mt='0px'>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                        </NumberInputStepper>
                    </NumberInput>
                </Box>
                <Box mt='0px' ml='80px' w='100%'>
                    <Center>
                        <Text mt='-30px' fontSize={22} fontWeight={600} fontFamily='Cocomat' >
                            Высота ворот</Text>
                    </Center>
                    <NumberInput
                        step={100}
                        defaultValue={1800}
                        min={1800}
                        max={3000}
                        w='100%'
                        mt='10px'
                        size='lg'
                        ml='0px'
                        onChange={newHeight => { setHeight(newHeight) }}
                    >
                        <NumberInputField />
                        <Box float='right' w='55px' h='46px' bg='#e9ecef' mt='-46.5px' mr='25px' zIndex={2} p={2} >
                            <Text fontWeight={600} ml='7px' mt='2.5px' >MM</Text>
                        </Box>
                        <NumberInputStepper mt='0px'>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                        </NumberInputStepper>
                    </NumberInput>
                </Box>
                <Button
                    variant='solid'
                    colorScheme='blue'
                    borderRadius={0}
                    w='100%'
                    h='65px'
                    fontSize={20}
                    mt='-10px'
                    ml='3px'
                    border='solid 1px'
                    onClick={sendM}
                >Расчитать</Button>
            </VStack>
            <HStack gap='20px' mt='20px' >
                <Text fontSize={20} fontFamily="RobotoRegular" >Цена от:</Text>
                <Text fontSize={35} fontWeight={700} fontFamily='RobotoRegular'>{cost[1][0]}₽ <span className="cost" >35 000 ₽</span></Text>
            </HStack>
            <HStack w='100%' mt='20px' >
                <Box w='50%'>
                    <HStack>
                        <Icon as={FcBusinessman} w='40px' h='40px' />
                        <Text fontSize={14} >Выездной менеджер бесплатно</Text>
                    </HStack>
                </Box>
                <Box w='50'>
                    <HStack>
                        <Icon as={TbTruckDelivery} w='40px' h='40px' />
                        <Text fontSize={14}>Доставка до 20км от МКАД в подарок </Text>
                    </HStack>
                </Box>
            </HStack>
        </Box>
        <Button
            background='none'
            border='none'
            cursor='pointer'
            overflow='hidden'
            outline='none'
            w='1px'
            h='1px'
            onClick={() => {
                setsaleBlockM5(prev => ({ ...prev, display: "none" }));
                setCrossM5(prev => ({ ...prev, display: "none" }));
                inp5.value = '';
                inp5_2.value = '';
            }}  >
            <img
                alt=''
                src={require('../../../img/cross-svgrepo-com.svg').default}
                style={crossM5} />
        </Button>


        {/*ОТКРЫВАЮЩИЙСЯ БЛОК 6 */}

        <Box style={saleBlockM6} className='sss' >
            <Text fontFamily='RobotoRegular' fontSize={29} textAlign='center' mt='30px' mr='0px' >Распашные ворота из профлиста</Text>
            <Center>
                <Image
                    src={require('../../../img/otkatnie/12.png')}
                    margin='15px 0 0 0px'
                    w='450px'
                    h='175px'
                />
            </Center>
            <Text
                textAlign='left'
                w='95%'
                fontFamily='RobotoLight'
                mt='30px'
                fontSize='17pt'
                pos='relative'
            >Откатные ворота выполены из жесткого металлического каркаса.
                При изготовлении используется профильные трубы 60x40, 40х20 2мм. После изготовления наши ворота отправляются на полимерную покраску.</Text>
            <VStack m='0px 0 0 0px' gap={8} >
                <Box w='100%' h='60px' >
                    <Center>
                        <Text mt='10px' fontSize={22} fontWeight={600} fontFamily='Cocomat' >Имя</Text>
                    </Center>
                    <Input id='inp6' w='100%' h='45px' borderRadius={0} mt='10px' onChange={x => setName(x.target.value)} />
                </Box>
                <Box w='100%' h='60px' >
                    <Center>
                        <Text mt='10px' fontSize={22} fontWeight={600} fontFamily='Cocomat' >Телефон</Text>
                    </Center>
                    <InputGroup>
                        <InputLeftAddon children='+7' h='45px' mt='10px' />
                        <Input
                            w='303px'
                            h='45px'
                            borderRadius={0}
                            mt='10px'
                            id='inp6.2'
                            maxLength={10}
                            placeholder="(987) 654-32-10"
                            onChange={x => setNumber(x.target.value)}
                        />
                    </InputGroup>
                </Box>
                <Box mt='20px' ml='80px' mr='0px' w='100%' >
                    <Center><Text mt='10px' fontSize={22} fontWeight={600} fontFamily='Cocomat' >Ширина ворот</Text></Center>
                    <NumberInput
                        step={100}
                        defaultValue={3000}
                        min={3000}
                        max={6200}
                        w='100%'
                        mt='10px'
                        size='lg'
                        ml='0px'
                        onChange={newWeight => { setWeight(newWeight) }}
                    >
                        <NumberInputField />
                        <Box float='right' w='55px' h='46px' bg='#e9ecef' mt='-46.5px' mr='25px' zIndex={2} p={2} >
                            <Text fontWeight={600} ml='7px' mt='2.5px' >MM</Text>
                        </Box>
                        <NumberInputStepper mt='0px'>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                        </NumberInputStepper>
                    </NumberInput>
                </Box>
                <Box mt='0px' ml='80px' w='100%'>
                    <Center>
                        <Text mt='-30px' fontSize={22} fontWeight={600} fontFamily='Cocomat' >
                            Высота ворот</Text>
                    </Center>
                    <NumberInput
                        step={100}
                        defaultValue={1800}
                        min={1800}
                        max={3000}
                        w='100%'
                        mt='10px'
                        size='lg'
                        ml='0px'
                        onChange={newHeight => { setHeight(newHeight) }}
                    >
                        <NumberInputField />
                        <Box float='right' w='55px' h='46px' bg='#e9ecef' mt='-46.5px' mr='25px' zIndex={2} p={2} >
                            <Text fontWeight={600} ml='7px' mt='2.5px' >MM</Text>
                        </Box>
                        <NumberInputStepper mt='0px'>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                        </NumberInputStepper>
                    </NumberInput>
                </Box>
                <Button
                    variant='solid'
                    colorScheme='blue'
                    borderRadius={0}
                    w='100%'
                    h='65px'
                    fontSize={20}
                    mt='-10px'
                    ml='3px'
                    border='solid 1px'
                    onClick={sendM}
                >Расчитать</Button>
            </VStack>
            <HStack gap='20px' mt='20px' >
                <Text fontSize={20} fontFamily="RobotoRegular" >Цена от:</Text>
                <Text fontSize={35} fontWeight={700} fontFamily='RobotoRegular'>{cost[1][1]}₽ <span className="cost" >35 000 ₽</span></Text>
            </HStack>
            <HStack w='100%' mt='20px' >
                <Box w='50%'>
                    <HStack>
                        <Icon as={FcBusinessman} w='40px' h='40px' />
                        <Text fontSize={14} >Выездной менеджер бесплатно</Text>
                    </HStack>
                </Box>
                <Box w='50'>
                    <HStack>
                        <Icon as={TbTruckDelivery} w='40px' h='40px' />
                        <Text fontSize={14}>Доставка до 20км от МКАД в подарок </Text>
                    </HStack>
                </Box>
            </HStack>
        </Box>
        <Button
            background='none'
            border='none'
            cursor='pointer'
            overflow='hidden'
            outline='none'
            w='1px'
            h='1px'
            onClick={() => {
                setsaleBlockM6(prev => ({ ...prev, display: "none" }));
                setCrossM6(prev => ({ ...prev, display: "none" }));
                inp6.value = '';
                inp6_2.value = '';
            }}  >
            <img
                alt=''
                src={require('../../../img/cross-svgrepo-com.svg').default}
                style={crossM6} />
        </Button>


        {/*ОТКРЫВАЮЩИЙСЯ БЛОК 7 */}

        <Box style={saleBlockM7} className='sss' >
            <Text fontFamily='RobotoRegular' fontSize={29} textAlign='center' mt='30px' mr='0px' >Распашные ворота из штакетника</Text>
            <Center>
                <Image
                    src={require('../../../img/otkatnie/13.png')}
                    margin='15px 0 0 0px'
                    w='450px'
                    h='175px'
                />
            </Center>
            <Text
                textAlign='left'
                w='95%'
                fontFamily='RobotoLight'
                mt='30px'
                fontSize='17pt'
                pos='relative'
            >Откатные ворота выполены из жесткого металлического каркаса.
                При изготовлении используется профильные трубы 60x40, 40х20 2мм. После изготовления наши ворота отправляются на полимерную покраску.</Text>
            <VStack m='0px 0 0 0px' gap={8} >
                <Box w='100%' h='60px' >
                    <Center>
                        <Text mt='10px' fontSize={22} fontWeight={600} fontFamily='Cocomat' >Имя</Text>
                    </Center>
                    <Input id='inp7' w='100%' h='45px' borderRadius={0} mt='10px' onChange={x => setName(x.target.value)} />
                </Box>
                <Box w='100%' h='60px' >
                    <Center>
                        <Text mt='10px' fontSize={22} fontWeight={600} fontFamily='Cocomat' >Телефон</Text>
                    </Center>
                    <InputGroup>
                        <InputLeftAddon children='+7' h='45px' mt='10px' />
                        <Input
                            w='303px'
                            h='45px'
                            borderRadius={0}
                            mt='10px'
                            id='inp7.2'
                            maxLength={10}
                            placeholder="(987) 654-32-10"
                            onChange={x => setNumber(x.target.value)}
                        />
                    </InputGroup>
                </Box>
                <Box mt='20px' ml='80px' mr='0px' w='100%' >
                    <Center><Text mt='10px' fontSize={22} fontWeight={600} fontFamily='Cocomat' >Ширина ворот</Text></Center>
                    <NumberInput
                        step={100}
                        defaultValue={3000}
                        min={3000}
                        max={6200}
                        w='100%'
                        mt='10px'
                        size='lg'
                        ml='0px'
                        onChange={newWeight => { setWeight(newWeight) }}
                    >
                        <NumberInputField />
                        <Box float='right' w='55px' h='46px' bg='#e9ecef' mt='-46.5px' mr='25px' zIndex={2} p={2} >
                            <Text fontWeight={600} ml='7px' mt='2.5px' >MM</Text>
                        </Box>
                        <NumberInputStepper mt='0px'>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                        </NumberInputStepper>
                    </NumberInput>
                </Box>
                <Box mt='0px' ml='80px' w='100%'>
                    <Center>
                        <Text mt='-30px' fontSize={22} fontWeight={600} fontFamily='Cocomat' >
                            Высота ворот</Text>
                    </Center>
                    <NumberInput
                        step={100}
                        defaultValue={1800}
                        min={1800}
                        max={3000}
                        w='100%'
                        mt='10px'
                        size='lg'
                        ml='0px'
                        onChange={newHeight => { setHeight(newHeight) }}
                    >
                        <NumberInputField />
                        <Box float='right' w='55px' h='46px' bg='#e9ecef' mt='-46.5px' mr='25px' zIndex={2} p={2} >
                            <Text fontWeight={600} ml='7px' mt='2.5px' >MM</Text>
                        </Box>
                        <NumberInputStepper mt='0px'>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                        </NumberInputStepper>
                    </NumberInput>
                </Box>
                <Button
                    variant='solid'
                    colorScheme='blue'
                    borderRadius={0}
                    w='100%'
                    h='65px'
                    fontSize={20}
                    mt='-10px'
                    ml='3px'
                    border='solid 1px'
                    onClick={sendM}
                >Расчитать</Button>
            </VStack>
            <HStack gap='20px' mt='20px' >
                <Text fontSize={20} fontFamily="RobotoRegular" >Цена от:</Text>
                <Text fontSize={35} fontWeight={700} fontFamily='RobotoRegular'>{cost[1][2]}₽ <span className="cost" >35 000 ₽</span></Text>
            </HStack>
            <HStack w='100%' mt='20px' >
                <Box w='50%'>
                    <HStack>
                        <Icon as={FcBusinessman} w='40px' h='40px' />
                        <Text fontSize={14} >Выездной менеджер бесплатно</Text>
                    </HStack>
                </Box>
                <Box w='50'>
                    <HStack>
                        <Icon as={TbTruckDelivery} w='40px' h='40px' />
                        <Text fontSize={14}>Доставка до 20км от МКАД в подарок </Text>
                    </HStack>
                </Box>
            </HStack>
        </Box>
        <Button
            background='none'
            border='none'
            cursor='pointer'
            overflow='hidden'
            outline='none'
            w='1px'
            h='1px'
            onClick={() => {
                setsaleBlockM7(prev => ({ ...prev, display: "none" }));
                setCrossM7(prev => ({ ...prev, display: "none" }));
                document.body.style.overflow = '';
                inp7.value = '';
                inp7_2.value = '';
            }}  >
            <img
                alt=''
                src={require('../../../img/cross-svgrepo-com.svg').default}
                style={crossM7} />
        </Button>



        {/*ОТКРЫВАЮЩИЙСЯ БЛОК 8 */}

        <Box style={saleBlockM8} className='sss' >
            <Text fontFamily='RobotoRegular' fontSize={29} textAlign='center' mt='30px' mr='0px' >Распашные ворота из поликарбоната</Text>
            <Center>
                <Image
                    src={require('../../../img/otkatnie/14.png')}
                    margin='15px 0 0 0px'
                    w='450px'
                    h='175px'
                />
            </Center>
            <Text
                textAlign='left'
                w='95%'
                fontFamily='RobotoLight'
                mt='30px'
                fontSize='17pt'
                pos='relative'
            >Откатные ворота выполены из жесткого металлического каркаса.
                При изготовлении используется профильные трубы 60x40, 40х20 2мм. После изготовления наши ворота отправляются на полимерную покраску.</Text>
            <VStack m='0px 0 0 0px' gap={8} >
                <Box w='100%' h='60px' >
                    <Center>
                        <Text mt='10px' fontSize={22} fontWeight={600} fontFamily='Cocomat' >Имя</Text>
                    </Center>
                    <Input id='inp8' w='100%' h='45px' borderRadius={0} mt='10px' onChange={x => setName(x.target.value)} />
                </Box>
                <Box w='100%' h='60px' >
                    <Center>
                        <Text mt='10px' fontSize={22} fontWeight={600} fontFamily='Cocomat' >Телефон</Text>
                    </Center>
                    <InputGroup>
                        <InputLeftAddon children='+7' h='45px' mt='10px' />
                        <Input
                            w='303px'
                            h='45px'
                            borderRadius={0}
                            mt='10px'
                            id='inp8.2'
                            maxLength={10}
                            placeholder="(987) 654-32-10"
                            onChange={x => setNumber(x.target.value)}
                        />
                    </InputGroup>
                </Box>
                <Box mt='20px' ml='80px' mr='0px' w='100%' >
                    <Center><Text mt='10px' fontSize={22} fontWeight={600} fontFamily='Cocomat' >Ширина ворот</Text></Center>
                    <NumberInput
                        step={100}
                        defaultValue={3000}
                        min={3000}
                        max={6200}
                        w='100%'
                        mt='10px'
                        size='lg'
                        ml='0px'
                        onChange={newWeight => { setWeight(newWeight) }}
                    >
                        <NumberInputField />
                        <Box float='right' w='55px' h='46px' bg='#e9ecef' mt='-46.5px' mr='25px' zIndex={2} p={2} >
                            <Text fontWeight={600} ml='7px' mt='2.5px' >MM</Text>
                        </Box>
                        <NumberInputStepper mt='0px'>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                        </NumberInputStepper>
                    </NumberInput>
                </Box>
                <Box mt='0px' ml='80px' w='100%'>
                    <Center>
                        <Text mt='-30px' fontSize={22} fontWeight={600} fontFamily='Cocomat' >
                            Высота ворот</Text>
                    </Center>
                    <NumberInput
                        step={100}
                        defaultValue={1800}
                        min={1800}
                        max={3000}
                        w='100%'
                        mt='10px'
                        size='lg'
                        ml='0px'
                        onChange={newHeight => { setHeight(newHeight) }}
                    >
                        <NumberInputField />
                        <Box float='right' w='55px' h='46px' bg='#e9ecef' mt='-46.5px' mr='25px' zIndex={2} p={2} >
                            <Text fontWeight={600} ml='7px' mt='2.5px' >MM</Text>
                        </Box>
                        <NumberInputStepper mt='0px'>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                        </NumberInputStepper>
                    </NumberInput>
                </Box>
                <Button
                    variant='solid'
                    colorScheme='blue'
                    borderRadius={0}
                    w='100%'
                    h='65px'
                    fontSize={20}
                    mt='-10px'
                    ml='3px'
                    border='solid 1px'
                    onClick={sendM}
                >Расчитать</Button>
            </VStack>
            <HStack gap='20px' mt='20px' >
                <Text fontSize={20} fontFamily="RobotoRegular" >Цена от:</Text>
                <Text fontSize={35} fontWeight={700} fontFamily='RobotoRegular'>{cost[1][3]}₽ <span className="cost" >35 000 ₽</span></Text>
            </HStack>
            <HStack w='100%' mt='20px' >
                <Box w='50%'>
                    <HStack>
                        <Icon as={FcBusinessman} w='40px' h='40px' />
                        <Text fontSize={14} >Выездной менеджер бесплатно</Text>
                    </HStack>
                </Box>
                <Box w='50'>
                    <HStack>
                        <Icon as={TbTruckDelivery} w='40px' h='40px' />
                        <Text fontSize={14}>Доставка до 20км от МКАД в подарок </Text>
                    </HStack>
                </Box>
            </HStack>
        </Box>
        <Button
            background='none'
            border='none'
            cursor='pointer'
            overflow='hidden'
            outline='none'
            w='1px'
            h='1px'
            onClick={() => {
                setsaleBlockM8(prev => ({ ...prev, display: "none" }));
                setCrossM8(prev => ({ ...prev, display: "none" }));
                inp8.value = '';
                inp8_2.value = '';
            }}  >
            <img
                alt=''
                src={require('../../../img/cross-svgrepo-com.svg').default}
                style={crossM8} />
        </Button>




        {/*ОТКРЫВАЮЩИЙСЯ БЛОК 9 */}

        <Box style={saleBlockM9} className='sss' >
            <Text fontFamily='RobotoRegular' fontSize={29} textAlign='center' mt='30px' mr='0px' >Распашные ворота из 3D сетки</Text>
            <Center>
                <Image
                    src={require('../../../img/otkatnie/15.png')}
                    margin='15px 0 0 0px'
                    w='450px'
                    h='175px'
                />
            </Center>
            <Text
                textAlign='left'
                w='95%'
                fontFamily='RobotoLight'
                mt='30px'
                fontSize='17pt'
                pos='relative'
            >Откатные ворота выполены из жесткого металлического каркаса.
                При изготовлении используется профильные трубы 60x40, 40х20 2мм. После изготовления наши ворота отправляются на полимерную покраску.</Text>
            <VStack m='0px 0 0 0px' gap={8} >
                <Box w='100%' h='60px' >
                    <Center>
                        <Text mt='10px' fontSize={22} fontWeight={600} fontFamily='Cocomat' >Имя</Text>
                    </Center>
                    <Input id='inp9' w='100%' h='45px' borderRadius={0} mt='10px' onChange={x => setName(x.target.value)} />
                </Box>
                <Box w='100%' h='60px' >
                    <Center>
                        <Text mt='10px' fontSize={22} fontWeight={600} fontFamily='Cocomat' >Телефон</Text>
                    </Center>
                    <InputGroup>
                        <InputLeftAddon children='+7' h='45px' mt='10px' />
                        <Input
                            w='303px'
                            h='45px'
                            borderRadius={0}
                            mt='10px'
                            id='inp9.2'
                            maxLength={10}
                            placeholder="(987) 654-32-10"
                            onChange={x => setNumber(x.target.value)}
                        />
                    </InputGroup>
                </Box>
                <Box mt='20px' ml='80px' mr='0px' w='100%' >
                    <Center><Text mt='10px' fontSize={22} fontWeight={600} fontFamily='Cocomat' >Ширина ворот</Text></Center>
                    <NumberInput
                        step={100}
                        defaultValue={3000}
                        min={3000}
                        max={6200}
                        w='100%'
                        mt='10px'
                        size='lg'
                        ml='0px'
                        onChange={newWeight => { setWeight(newWeight) }}
                    >
                        <NumberInputField />
                        <Box float='right' w='55px' h='46px' bg='#e9ecef' mt='-46.5px' mr='25px' zIndex={2} p={2} >
                            <Text fontWeight={600} ml='7px' mt='2.5px' >MM</Text>
                        </Box>
                        <NumberInputStepper mt='0px'>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                        </NumberInputStepper>
                    </NumberInput>
                </Box>
                <Box mt='0px' ml='80px' w='100%'>
                    <Center>
                        <Text mt='-30px' fontSize={22} fontWeight={600} fontFamily='Cocomat' >
                            Высота ворот</Text>
                    </Center>
                    <NumberInput
                        step={100}
                        defaultValue={1800}
                        min={1800}
                        max={3000}
                        w='100%'
                        mt='10px'
                        size='lg'
                        ml='0px'
                        onChange={newHeight => { setHeight(newHeight) }}
                    >
                        <NumberInputField />
                        <Box float='right' w='55px' h='46px' bg='#e9ecef' mt='-46.5px' mr='25px' zIndex={2} p={2} >
                            <Text fontWeight={600} ml='7px' mt='2.5px' >MM</Text>
                        </Box>
                        <NumberInputStepper mt='0px'>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                        </NumberInputStepper>
                    </NumberInput>
                </Box>
                <Button
                    variant='solid'
                    colorScheme='blue'
                    borderRadius={0}
                    w='100%'
                    h='65px'
                    fontSize={20}
                    mt='-10px'
                    ml='3px'
                    border='solid 1px'
                    onClick={sendM}
                >Расчитать</Button>
            </VStack>
            <HStack gap='20px' mt='20px' >
                <Text fontSize={20} fontFamily="RobotoRegular" >Цена от:</Text>
                <Text fontSize={35} fontWeight={700} fontFamily='RobotoRegular'>{cost[1][4]}₽ <span className="cost" >35 000 ₽</span></Text>
            </HStack>
            <HStack w='100%' mt='20px' >
                <Box w='50%'>
                    <HStack>
                        <Icon as={FcBusinessman} w='40px' h='40px' />
                        <Text fontSize={14} >Выездной менеджер бесплатно</Text>
                    </HStack>
                </Box>
                <Box w='50'>
                    <HStack>
                        <Icon as={TbTruckDelivery} w='40px' h='40px' />
                        <Text fontSize={14}>Доставка до 20км от МКАД в подарок </Text>
                    </HStack>
                </Box>
            </HStack>
        </Box>
        <Button
            background='none'
            border='none'
            cursor='pointer'
            overflow='hidden'
            outline='none'
            w='1px'
            h='1px'
            onClick={() => {
                setsaleBlockM9(prev => ({ ...prev, display: "none" }));
                setCrossM9(prev => ({ ...prev, display: "none" }));
                inp9.value = '';
                inp9_2.value = '';
            }}  >
            <img
                alt=''
                src={require('../../../img/cross-svgrepo-com.svg').default}
                style={crossM9} />
        </Button>

    </Box>
}