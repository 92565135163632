import Header from "./Header";
import {
    Box
} from '@chakra-ui/react';
import Description from "./child/description";
import TopHeader from "./topHeader";
import BarMobile from "./child/menu";

export default function MainHeader() {

    return <Box h='700px'>
        <TopHeader />
        <BarMobile />
        <Header />
        <Description />
    </Box>
}