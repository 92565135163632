import {
    Box,
    Center,
    Text,
    RadioGroup,
    Stack,
    Radio,
    Menu,
    MenuButton,
    MenuList,
    Button,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    Checkbox,
    MenuItemOption,
    MenuOptionGroup,
    VStack
} from "@chakra-ui/react";
import { Link } from "react-scroll";
import { ChevronDownIcon } from '@chakra-ui/icons';
import React from "react";

export default function Calculator() {

    const [type, setType] = React.useState('swing');
    const [width, setWidth] = React.useState(3000);
    const [height, setHeight] = React.useState(1800);
    const [zashivka, setZashivka] = React.useState('Без зашивки');
    const [kalitka, setKalitka] = React.useState('Без калитки');
    const [fundament, setFundament] = React.useState('Без фундамента');
    const [avtomatika, setAvtomatika] = React.useState('Без автоматики');
    const [mounting, setMounting] = React.useState(true);
    const [cost, setCost] = React.useState(43000);


    const handleChange = (type, width, height, zashivka, kalitka, fundament, avtomatika, mounting) => {
        console.log(type, width, height, zashivka, kalitka, fundament, avtomatika, mounting);
        let target = 0;

        if (type === "swing") {
            // ширина
            if (width >= 3100 && width <= 4000) target += 2000;
            else if (width > 4000 && width <= 4500) target += 4000;
            else if (width > 4500 && width <= 5000) target += 7000;
            else if (width > 5000 && width <= 5500) target += 10000;
            else if (width > 5500 && width <= 6000) target += 14000;
            else if (width > 6000) target += 16000;
            // высота
            if (height >= 2100 && height <= 2200) target += 2000;
            else if (height > 2200 && height <= 2500) target += 3000;
            else if (height > 2500 && height <= 2700) target += 5000;
            else if (height > 2700) target += 5500;

            if (fundament === "Свайный") target += 12000;

        } else if (type === "sliding") {
            target += 3000;

            // ширина
            if (width >= 3100 && width <= 3700) target += 1000;
            else if (width > 3700 && width <= 4200) target += 2000;
            else if (width > 4200 && width <= 4700) target += 5000;
            else if (width > 4700 && width <= 5200) target += 7000;
            else if (width > 5200) target += 30000;
            // высота
            if (height >= 2200 && height <= 2500) target += 4000;
            else if (height > 2500) target += 9000;

            if (fundament === "Свайный") target += 19000;
            else if (fundament === "Бетонный") target += 35000;
        };

        if (zashivka === "Профнастил 1 сторона") target += 7000;
        else if (zashivka === "Профнастил 2 стороны") target += 14000;
        else if (zashivka === "Евроштакетник") target += 12000;
        else if (zashivka === "Евроштакетник с 2х сторон") target += 21000;
        else if (zashivka === "Решётка") target += 15000;
        else if (zashivka === "Доска") target += 19000;
        else if (zashivka === "3D Сетка") target += 10000;
        else if (zashivka === "Решётка с элементами ковки") target += 35000;
        else if (zashivka === "Поликарбонат") target += 12000;

        if (kalitka === "Встроенная") target += 17000;
        else if (kalitka === "Отдельностоящая") target += 20000;

        if (avtomatika === "Найс рд 400 (Италия)") target += 35000;
        else if (avtomatika === "Найс робус 600 (Италия)") target += 45000;
        else if (avtomatika === "Каме bx 708 (Италия)") target += 45000;
        else if (avtomatika === "Алютех рто 500 (Белоруссия)") target += 35000;

        target += mounting ? 19000 : 0;
        setCost(25000 + target);
    };

    return <Box
        w='100%'
        h='1350px'
        mt='0px'
        bg='gray.100'
    >
        <Center>
            <Box
                w='90%'
                h='850px'
                p={7}
                id='calculator'
                mt='0px'
                bg='gray.100'
            >
                <hr style={{
                    color: '#1974ff',
                    backgroundColor: '#1974ff',
                    height: .8,
                    borderColor: '#1974ff',
                    width: 50,
                    marginTop: 0,
                    marginLeft: 0
                }} />
                <Text color='#1C1C1C' fontSize={29} fontWeight={400} mt='20px' ml='0px' fontFamily='SportsWorld' >Калькулятор стоимости ворот</Text>

                <RadioGroup id='variant' ml='0px' mt='20px' onChange={newType => { setType(newType); handleChange(newType, width, height, zashivka, kalitka, fundament, avtomatika, mounting); }} value={type} >
                    <Stack direction='row' gap={1} >
                        <Radio
                            value={'swing'}
                            size='lg'
                            fontWeight={900}
                        ><p style={{ fontWeight: 600, fontSize: 20, fontFamily: 'Cocomat' }} >Распашные ворота</p></Radio>
                        <Radio
                            value={'sliding'}
                            size='lg'
                        ><p style={{ fontWeight: 600, fontSize: 20, fontFamily: 'Cocomat' }} > Откатные ворота</p></Radio>
                    </Stack>
                </RadioGroup>

                <VStack gap={5} >
                    <Box mt='20px' ml='0px' mr='0px' w='110%' >
                        <Center><Text mt='10px' fontSize={24} fontWeight={600} fontFamily='Cocomat' >Ширина ворот</Text></Center>
                        <NumberInput
                            step={100}
                            defaultValue={3000}
                            min={3000}
                            max={6200}
                            w='100%'
                            mt='10px'
                            size='lg'
                            ml='0px'
                            border='solid 0px black'
                            onChange={newWidth => { setWidth(newWidth); handleChange(type, newWidth, height, zashivka, kalitka, fundament, avtomatika, mounting); }}
                        >
                            <NumberInputField />
                            <Box float='right' w='55px' h='46px' bg='#e9ecef' mt='-46.5px' mr='25px' zIndex={2} p={2} >
                                <Text fontWeight={600} ml='7px' mt='2.5px' >MM</Text>
                            </Box>
                            <NumberInputStepper mt='0px'>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>
                    </Box>

                    <Box mt='10px' ml='0px' w='110%'>
                        <Center><Text mt='10px' fontSize={24} fontWeight={600} fontFamily='Cocomat' >Высота ворот</Text></Center>
                        <NumberInput
                            step={100}
                            defaultValue={1800}
                            min={1800}
                            max={3000}
                            w='100%'
                            mt='10px'
                            size='lg'
                            ml='0px'
                            border='solid 0px black'
                            onChange={newHeight => { setHeight(newHeight); handleChange(type, width, newHeight, zashivka, kalitka, fundament, avtomatika, mounting); }}
                        >
                            <NumberInputField />
                            <Box float='right' w='55px' h='46px' bg='#e9ecef' mt='-46.5px' mr='25px' zIndex={2} p={2} >
                                <Text fontWeight={600} ml='7px' mt='2.5px' >MM</Text>
                            </Box>
                            <NumberInputStepper mt='0px'>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>
                    </Box>

                    <Box w='110%' h='100px'>
                        <Center><Text fontSize={24} fontWeight={600} fontFamily='Cocomat' >Отделка</Text></Center>
                        <Menu >
                            <MenuButton
                                as={Button}
                                rightIcon={<ChevronDownIcon />}
                                w='100%'
                                ml='0px'
                                mt='10px'
                                float='left'
                                h='50px'
                                fontSize={22}
                                fontWeight={400}
                                border='solid 1px black'
                            >
                                {zashivka}
                            </MenuButton>
                            <MenuList width='110%'>
                                <MenuOptionGroup type="radio" onChange={newZashivka => { setZashivka(newZashivka); handleChange(type, width, height, newZashivka, kalitka, fundament, avtomatika, mounting); }}>
                                    <MenuItemOption value="Без зашивки">Без зашивки</MenuItemOption>
                                    <MenuItemOption value="Профнастил 1 сторона">Профнастил 1 сторона</MenuItemOption>
                                    <MenuItemOption value="Профнастил 2 стороны">Профнастил 2 стороны</MenuItemOption>
                                    <MenuItemOption value="Евроштакетник">Евроштакетник</MenuItemOption>
                                    <MenuItemOption value="Евроштакетник с 2х сторон">Евроштакетник с 2х сторон</MenuItemOption>
                                    <MenuItemOption value="Решётка">Решётка</MenuItemOption>
                                    <MenuItemOption value="Доска">Доска</MenuItemOption>
                                    <MenuItemOption value="3D Сетка">3D Сетка</MenuItemOption>
                                    <MenuItemOption value="Решётка с элементами ковки">Решётка с элементами ковки</MenuItemOption>
                                    <MenuItemOption value="Поликарбонат">Поликарбонат</MenuItemOption>
                                </MenuOptionGroup>
                            </MenuList>
                        </Menu>
                    </Box>


                    <Box w='110%' h='100px'>
                        <Center><Text fontSize={24} fontWeight={600} fontFamily='Cocomat' >Фундамент</Text></Center>
                        <Menu >
                            <MenuButton
                                as={Button}
                                rightIcon={<ChevronDownIcon />}
                                w='100%'
                                ml='0px'
                                mt='10px'
                                float='left'
                                h='50px'
                                fontSize={22}
                                fontWeight={400}
                                border='solid 1px black' >
                                {fundament}
                            </MenuButton>
                            <MenuList w='136%' >
                                <MenuOptionGroup type="radio" onChange={newFundament => { setFundament(newFundament); handleChange(type, width, height, zashivka, kalitka, newFundament, avtomatika, mounting); }}>
                                    <MenuItemOption value="Без автоматики">Без фундамента</MenuItemOption>
                                    <MenuItemOption value="Свайный">Свайный</MenuItemOption>
                                    <MenuItemOption value="Бетонный">Бетонный</MenuItemOption>
                                </MenuOptionGroup>
                            </MenuList>
                        </Menu>
                    </Box>


                    <Box w='110%' h='100px' >
                        <Center><Text fontSize={24} fontWeight={600} fontFamily='Cocomat'>Автоматика</Text></Center>
                        <Menu >
                            <MenuButton
                                as={Button}
                                rightIcon={<ChevronDownIcon />}
                                w='100%'
                                ml='0px'
                                mt='10px'
                                float='left'
                                h='50px'
                                fontSize={22}
                                fontWeight={400}
                                border='solid 1px black' >
                                {avtomatika}
                            </MenuButton>
                            <MenuList w='110%' >
                                <MenuOptionGroup type="radio" onChange={newAvtomatika => { setAvtomatika(newAvtomatika); handleChange(type, width, height, zashivka, kalitka, fundament, newAvtomatika, mounting); }}>
                                    <MenuItemOption value="Без автоматики">Без автоматики</MenuItemOption>
                                    <MenuItemOption value="Найс рд 400 (Италия)">Найс рд 400 (Италия)</MenuItemOption>
                                    <MenuItemOption value="Найс робус 600 (Италия)">Найс робус 600 (Италия)</MenuItemOption>
                                    <MenuItemOption value="Каме bx 708 (Италия)">Каме bx 708 (Италия)</MenuItemOption>
                                    <MenuItemOption value="Алютех рто 500 (Белоруссия)">Алютех рто 500 (Белоруссия)</MenuItemOption>
                                </MenuOptionGroup>
                            </MenuList>
                        </Menu>
                    </Box>


                    <Box w='110%' h='100px' mt='200px' >
                        <Center><Text mt='0px' fontSize={24} fontWeight={600} fontFamily='Cocomat' >Калитка</Text></Center>
                        <Menu >
                            <MenuButton
                                as={Button}
                                rightIcon={<ChevronDownIcon />}
                                w='100%'
                                h='50px'
                                fontSize={22}
                                fontWeight={400}
                                border='solid 1px black'
                            >
                                {kalitka}
                            </MenuButton>
                            <MenuList w='110%'>
                                <MenuOptionGroup type="radio" onChange={newKalitka => { setKalitka(newKalitka); handleChange(type, width, height, zashivka, newKalitka, fundament, avtomatika, mounting); }}>
                                    <MenuItemOption value="Без калитки">Без калитки</MenuItemOption>
                                    <MenuItemOption value="Встроенная">Встроенная</MenuItemOption>
                                    <MenuItemOption value="Отдельностоящая">Отдельностоящая</MenuItemOption>
                                </MenuOptionGroup>
                            </MenuList>
                        </Menu>
                    </Box>
                </VStack>

                <Checkbox
                    defaultChecked
                    size='lg'
                    float='left'
                    ml='-10px'
                    mt='15px'
                    onChange={toMount => { setMounting(toMount.target.checked); handleChange(type, width, height, zashivka, kalitka, fundament, avtomatika, toMount.target.checked); }}
                ><p style={{ fontSize: 23, fontFamily: 'Cocomat' }}>Монтаж</p></Checkbox>

                <Text fontSize={25} fontWeight={400} float='right' mr='16px' mt='10px' >Цена: <span id="costStyle">{cost}</span> руб.*</Text>

                <Link to='application' smooth={true} >
                    <Button w='100%' mt='30px' ml='0px' colorScheme='red' h='45px' fontSize={20}>Заказать звонок</Button>
                </Link>
                <a href='tel:+79257096009'
                    onClick={() => {
                        window.ym(90042661, 'reachGoal', 'clicked_on_phone')
                        console.log('Цель метрики на нажатие номера выполнена');
                    }}><Button w='100%' mt='12px' colorScheme='blue' h='45px' fontSize={20}>Звонок менеджеру</Button></a>

                <Center>
                    <Text mt='20px' fontSize={12} fontFamily='RobotoLight' >* Цена примерная в связи с нестабильностью на рынке стройматериалов.</Text>
                </Center>
            </Box>
        </Center >
    </Box >
}