import MainHeader from "./components/Laptop/Header/MainHeader";
import Promokod from "./components/Laptop/Promokod/promokod";
import Catalog from "./components/Laptop/Catalog/catalog";
import Calculator from "./components/Laptop/Calculator/calculator";
import FreeTrip from "./components/Laptop/FreeTrip";
import AboutUs from "./components/Laptop/AboutUs/index";
import Application from "./components/Laptop/Application/application";
import Footer from "./components/Laptop/Footer/index";
import TableBlock from "./components/Laptop/Table/table";
import Advantages from "./components/Laptop/Advantages/advantages";
import OurWork from "./components/Laptop/OurWork/ourWork";

function App() {
  return <>
  <MainHeader />
  <Promokod />
  <Catalog />
  <Advantages />
  <Calculator />
  <OurWork />
  <FreeTrip />
  <AboutUs />
  <TableBlock />
  <Application />
  <Footer />
  </>
}

export default App;
