import {
    Box,
    Text,
    Image
} from "@chakra-ui/react";

export default function TopHeader() {
    return <Box w='100%' h='80px' pos='fixed' zIndex={4} bg='#FFFFFF' top='-8px' >

        <Image w='200px' mt='-55px' ml='-6%' src={require('../../../img/logo-Black.png')} />

        <Text color='gray.700' fontSize={20} fontWeight={700} className='big' mt='-130px' float='right' mr='3%' fontFamily='RobotoRegular' ><a href='tel:+79257096009'
            onClick={() => {
                window.ym(90042661, 'reachGoal', 'clicked_on_phone')
                console.log('Цель метрики на нажатие номера выполнена');
            }} display='block'>+7 (925) 709-60-09</a></Text>

        <Text color='gray' fontSize={16} float='right' mr='3%' mt='-100px' fontFamily='RobotoRegular' ><a href="https://wa.me/+79257096009"
            onClick={() => {
                window.ym(90042661, 'reachGoal', 'clicked_on_messenger_whatsapp')
                console.log('Цель метрики на нажатие WhatsApp');
            }} className='big' >Написать нам в Whatsapp</a></Text>

    </Box>
}