import MainHeader from "./components/Mobile/Header/MainHeader";
import Promokod from "./components/Mobile/Promokod/promokod";
import Catalog from "./components/Mobile/Catalog/catalog"
import Calculator from "./components/Mobile/Calculator/calculator";
import OurWork from "./components/Mobile/OurWork/OurWork"
import FreeTrip from "./components/Mobile/FreeTrip";
import AboutUs from "./components/Mobile/AboutUs/index";
import Application from "./components/Mobile/Application/application";
import Footer from "./components/Mobile/Footer/index";
import Advantages from "./components/Mobile/Advantages/advantages";

function App() {
  return <>
    <MainHeader />
    <Promokod />
    <Catalog />
    <Advantages />
    <Calculator />
    <FreeTrip />
    <OurWork />
    <AboutUs />
    <Application />
    <Footer />
  </>
}

export default App;
