import {
    Box,
    Center,
    HStack,
    Text,
    Icon,
    Button
} from "@chakra-ui/react";
import { GrMoney } from "react-icons/gr";

export default function Promokod() {

    return <Box w='100%' h='300px'>
        <Center>
            <Box w='80%' minW='900px' maxW='1200px' h='270px' borderRadius={20} className='promo' p={5} >
                <Text fontFamily='RobotoRegular' fontSize={40} ml='40px' w='450px' >Получите скидку <span style={{ fontFamily: 'SportsWorld' }}>5000</span>₽</Text>
                <Icon as={GrMoney} float='right' w='45px' h='45px' mt='-70px' color='#f7931a' />
                <HStack gap='80px' >
                    <Text
                        fontFamily='RobotoLight'
                        fontSize={27}
                        ml='40px'
                        lineHeight='45px'
                    >При оформление заказа прямо сейчас просто скажите<br /> менеджеру промокод <span style={{ fontfamily: "RobotoRegular", fontSize: "28pt", fontWeight: "700"}} >"Фабрика"</span> и получите скидку<br /> <span style={{ textDecoration: 'underline' }} >5000 рублей на автоматические ворота.</span></Text>
                    <Button
                        variant='solid'
                        colorScheme='telegram'
                        borderRadius={0}
                        w='300px'
                        h='65px'
                        fontSize={20}
                        mt='-30px'
                        ml='3px'
                        border='solid 1px'
                        color='#FFFFFF'
                    ><a href='tel:+79257096009'
                        onClick={() => {
                            window.ym(90042661, 'reachGoal', 'clicked_on_phone')
                            console.log('Цель метрики на нажатие номера выполнена');
                        }} >Позвонить сейчас</a></Button>
                </HStack>
            </Box>
        </Center>
    </Box>
}