import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Box, HStack, Image, Text, Center } from "@chakra-ui/react";
import { Carousel } from "react-responsive-carousel";

export default function OurWork() {

    return <Box w='100%' h='560px' bg='#f8f9fa' pt='0px'>
        <Center>
            <hr style={{
                color: '#1974ff',
                backgroundColor: '#1974ff',
                height: .8,
                borderColor: '#1974ff',
                width: 60,
                marginTop: 30,
            }} />
        </Center>
        <Text textAlign='center' fontSize={36} mt='5px' fontFamily='SportsWorld'> Наши выполненные работы</Text>

        <Box w='100%' h='307px' mt='50px' >
            <Carousel autoPlay={true} interval={5000} infiniteLoop={true} showStatus={false} showThumbs={false} >
                <HStack>
                    <Image src={require("../../../img/raboti/raboti_1.jpeg")} h='300px' w='300px' />
                    <Image src={require("../../../img/raboti/raboti_2.jpeg")} h='300px' w='300px' />
                    <Image src={require("../../../img/raboti/raboti_3.jpeg")} h='300px' w='300px' />
                </HStack>
                <HStack>
                    <Image src={require("../../../img/raboti/raboti_4.jpeg")} h='300px' w='300px' />
                    <Image src={require("../../../img/raboti/raboti_5.jpeg")} h='300px' w='300px' />
                    <Image src={require("../../../img/raboti/raboti_6.jpeg")} h='300px' w='300px' />
                </HStack>
                <HStack>
                    <Image src={require("../../../img/raboti/raboti_7.jpeg")} h='300px' w='300px' />
                    <Image src={require("../../../img/raboti/raboti_8.jpeg")} h='300px' w='300px' />
                    <Image src={require("../../../img/raboti/raboti_9.jpeg")} h='300px' w='300px' />
                </HStack>
                <HStack>
                    <Image src={require("../../../img/raboti/raboti_10.jpeg")} h='300px' w='300px' />
                    <Image src={require("../../../img/raboti/raboti_11.jpeg")} h='300px' w='300px' />
                    <Image src={require("../../../img/raboti/raboti_12.jpeg")} h='300px' w='300px' />
                </HStack>
                <HStack>
                    <Image src={require("../../../img/raboti/raboti_13.jpeg")} h='300px' w='300px' />
                    <Image src={require("../../../img/raboti/raboti_14.jpeg")} h='300px' w='300px' />
                    <Image src={require("../../../img/raboti/raboti_15.jpeg")} h='300px' w='300px' />
                </HStack>
            </Carousel>
        </Box>
    </Box>
}