import { Box, Center, Text, Icon, HStack, Image } from "@chakra-ui/react";
import { FiPhoneCall } from "react-icons/fi";
import { AiOutlineMail, AiOutlineHome, AiOutlineClockCircle } from "react-icons/ai";
import { RiMessengerLine } from "react-icons/ri";
import { FaTelegram, FaWhatsapp, FaViber } from "react-icons/fa";
import { Link } from "react-scroll";

export default function FooterBlock() {
    return <Box w='100%' h='730px' bg='#f8f9fa' mt='0px' >
        <Center>
            <Box w='90%' h='325px'>
                <Center><Image src={require('../../../img/logo-Black.png')} w='250px' mt='-50px' /></Center>
                <Text w='100%' fontSize={16} fontWeight={500} mt='-100px' textAlign='center' ml='0px' >Производство и установка откатных, и распашных ворот в Москве, и Московской области.</Text>
                <Box h='300px' w='320px' float='right' mr='6%'>
                    <Text fontSize={24} fontWeight={400} mt='30px' mb='10px' >Контактная информация</Text>
                    <HStack>
                        <Icon as={FiPhoneCall} w='25px' h='20px' /><Text fontSize={19} fontWeight={400} mt='7px' ><a href='tel:+79257096009'
                            onClick={() => {
                                window.ym(90042661, 'reachGoal', 'clicked_on_phone')
                                console.log('Цель метрики на нажатие номера выполнена');
                            }}>+7 (925) 709-60-09</a></Text>
                    </HStack>
                    <HStack mt='7px'>
                        <Icon as={AiOutlineMail} w='25px' h='20px' />
                        <a href='mailto:fabrika.vorot@mail.ru'
                            onClick={() => {
                                window.ym(90042661, 'reachGoal', 'clicked_on_email')
                                console.log('Цель метрики на нажатие почты выполнена');
                            }} ><Text fontSize={19} fontWeight={400}  >fabrika.vorot@mail.ru</Text></a>
                    </HStack>
                    <HStack mt='7px'>
                        <Icon as={AiOutlineHome} w='25px' h='20px' /><Text fontSize={19} fontWeight={400} mt='10px' >ул. Ильинский тупик, 1, корп. 2, Красногорск</Text>
                    </HStack>
                    <HStack mt='7px'>
                        <Icon as={AiOutlineClockCircle} w='25px' h='20px' /><Text fontSize={19} fontWeight={400} mt='10px' >Режим работы с 8:00 до 22:00, ПН-ВС</Text>
                    </HStack>
                    <HStack mt='7px'>
                        <Icon as={RiMessengerLine} w='25px' h='20px' /><Text fontSize={19} fontWeight={400} mt='10px' >Мессенджеры</Text>
                    </HStack>
                    <HStack ml='30px' mt='7px' >
                        <a href="tg://resolve?domain=fabrikaVorot"
                            onClick={() => {
                                window.ym(90042661, 'reachGoal', 'clicked_on_messenger_telegram')
                                console.log('Цель метрики на нажатие Телеграм');
                            }}><Icon as={FaTelegram} w='35px' h='35px' /></a>
                        <a href="https://wa.me/+79257096009"
                            onClick={() => {
                                window.ym(90042661, 'reachGoal', 'clicked_on_messenger_whatsapp')
                                console.log('Цель метрики на нажатие WhatsApp');
                            }} ><Icon as={FaWhatsapp} w='35px' h='35px' /></a>
                        <a href="https://viber.click/79254333444"
                            onClick={() => {
                                window.ym(90042661, 'reachGoal', 'clicked_on_messenger_viber')
                                console.log('Цель метрики на нажатие Viber');
                            }}><Icon as={FaViber} w='35px' h='35px' mt='2px' /></a>
                    </HStack>
                </Box>
                <Box w='100%' h='200px' mt='365px' >
                    <HStack>
                        <Box w='100%' h='200px'>
                            <Link to='catalog' smooth={true}><Text className="foot" fontSize={16} fontWeight={500} mt='7px' >Каталог</Text></Link>
                            <Link to='advantages' smooth={true}><Text className="foot" fontSize={16} fontWeight={500} mt='7px' >Наши преимущества</Text></Link>
                            <Link to='calculator' smooth={true}><Text className="foot" fontSize={16} fontWeight={500} mt='7px' >Калькулятор</Text></Link>
                            <Link to='freeTrip' smooth={true}><Text className="foot" fontSize={16} fontWeight={500} mt='7px' >Бесплатный замер</Text></Link>
                        </Box>
                        <Box w='170px' h='200px'>
                            <Link to='aboutUs' smooth={true}><Text className="foot" fontSize={16} fontWeight={500} mt='7px' >О компании</Text></Link>
                            <Link to='carousel' smooth={true}><Text className="foot" fontSize={16} fontWeight={500} mt='7px' >Наши работы</Text></Link>
                            <Link to='application' smooth={true}><Text className="foot" fontSize={16} fontWeight={500} mt='7px' >Контакты</Text></Link>
                            <Link to='application' smooth={true}><Text className="foot" fontSize={16} fontWeight={500} mt='7px' >Доставка и оплата</Text></Link>
                        </Box>
                    </HStack>
                </Box>
            </Box>
        </Center>
    </Box>
}