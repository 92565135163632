import {
    Box,
    Center,
    Text,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Image
} from "@chakra-ui/react";

export default function AboutUs() {
    return <Box w='100%' h='900px' mt='400px' >
        <Center>
            <Box w='90%' minW='1000px' maxW='1300px' h='900px' id='aboutUs' >
                <Center>
                    <hr style={{
                        color: '#1974ff',
                        backgroundColor: '#1974ff',
                        height: .8,
                        borderColor: '#1974ff',
                        width: 60,
                        marginTop: 80,
                    }} />
                </Center>
                <Text textAlign='center' fontSize={36} mt='17px' fontFamily='SportsWorld' >О компании</Text>
                <Center>
                    <Text color='gray' textAlign='center' fontSize={19} w='800px' mt='5px' fontFamily='RobotoRegular' >
                        Компания "Фабрика Ворот" начало свою деятельность с 2010 года. Мы предлагаем: Собственное производство; Прямые поставки комплектующих от ведущих и проверенным временем производителей; Контроль качества на всех этапах производства, доставка собственным автопарком.</Text>
                </Center>
                <Accordion w={window.innerWidth > 1300 ? '430px' : '300px'} float='right' mr='3%' mt='50px' >
                    <AccordionItem border='none' >
                        <h2>
                            <AccordionButton>
                                <Box flex='1' textAlign='left' fontSize={20} fontFamily='Cocomat'  >
                                    Ищем новые решения
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4} fontFamily="RobotoRegular" fontSize={20} >
                            Для улучшения и повышения скорости как производства, так и монтажных работ, не теряя при этом качества. Изготавливаем ворота для любых клиентов, в том числе ворота для парковки, ворота для СТО, автомоек и прочих специфических организаций
                        </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem>
                        <h2>
                            <AccordionButton>
                                <Box flex='1' textAlign='left' fontSize={20} fontFamily='Cocomat'>
                                    Лучшие цены на рынке
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4} fontFamily="RobotoRegular" fontSize={20}>
                            У нас Вы можете купить ворота в Москве и Московской области по выгодным ценам, так как являемся производителями и делаем минимальные надбавки к стоимости.
                        </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem>
                        <h2>
                            <AccordionButton>
                                <Box flex='1' textAlign='left' fontSize={20} fontFamily='Cocomat'>
                                    Ра<span className='b'>б</span>отаем <span className='b'>б</span>ез предоплаты
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4} fontFamily="RobotoRegular" fontSize={20}>
                            Мы обеспечиваем превосходный сервис, доверяем нашем клиентам и не берем предоплату, а получаем по факту выполнения, если работа сделана не качественно, Вы не платите, а мы проводим дополнительное обучение сотрудников для исключения таких ситуаций.
                        </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem>
                        <h2>
                            <AccordionButton>
                                <Box flex='1' textAlign='left' fontSize={20} fontFamily='Cocomat'>
                                    Качественные ворота
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4} fontFamily="RobotoRegular" fontSize={20}>
                            Наши менеджеры контролируют производство на каждом этапе. Качественная сварка конструкции, нуждается в идеальной покраске – поэтому все наши изделия покрываются полимерным покрытием.
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>

                <Image
                    src={require('../../../img/background2.jpeg')}
                    w='600px'
                    h='400px'
                    float='left'
                    mt='50px'
                    ml={window.innerWidth > 1300 ? '100px' : '0px'}
                    id='aboutImg'
                    zIndex={2}
                    pos='absolute' />

                <Image
                    src={require('../../../img/background2 — копия.jpeg')}
                    w='400px'
                    h='300px'
                    float='left'
                    mt='280px'
                    ml='-72px'
                    zIndex={1}
                    pos='absolute'
                    display={window.innerWidth > 1370 ? 'block' : 'none'}
                    id='carousel'
                />

            </Box>
        </Center>

    </Box>
}