import {
    Box,
    Center,
    VStack,
    Text,
    Button
} from "@chakra-ui/react";

export default function Promokod() {

    return <Box w='100%' h='450px' mt='-85px' >
        <Center>
            <Box w='90%' h='360px' borderRadius={20} className='promo' p={2} >
                <Text fontFamily='RobotoRegular' fontSize={28} ml='15px' mt='7px' w='100%' >Получите скидку <span style={{ fontFamily: 'SportsWorld' }}>5000</span>₽</Text>
               <VStack gap='20px' >
                    <Text
                        fontFamily='RobotoLight'
                        fontSize={18}
                        ml='15px'
                        lineHeight='45px'
                    >При оформление заказа прямо сейчас просто скажите менеджеру промокод <span style={{ fontfamily: "RobotoRegular", fontSize: "24pt", fontWeight: "700"}} >"Фабрика"</span> и получите скидку<br /> <span style={{ textDecoration: 'underline' }} >5000 рублей на автоматические ворота.</span></Text>
                    <Button
                        variant='solid'
                        colorScheme='telegram'
                        borderRadius={0}
                        w='300px'
                        h='65px'
                        fontSize={20}
                        mt='0px'
                        ml='3px'
                        border='solid 1px'
                        color='#FFFFFF'
                    ><a href='tel:+79257096009'
                        onClick={() => {
                            window.ym(90042661, 'reachGoal', 'clicked_on_phone')
                            console.log('Цель метрики на нажатие номера выполнена');
                        }} >Позвонить сейчас</a></Button>
                </VStack> 
            </Box>
        </Center>
    </Box>
}