import Header from "./Header";
import Bar from "./child/menu";
import {
    Box
} from '@chakra-ui/react';
import Description from "./child/description";

export default function MainHeader() {

    return <Box h='700px' >
        <Header />
        <Bar />
        <Description />
    </Box>
}