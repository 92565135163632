import {
    Box,
    Center,
    Text,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Image
} from "@chakra-ui/react";

export default function AboutUs() {
    return <Box w='100%' h='max-content' mt='0px' >
        <Center>
            <Box w='90%' h='max-content' id='aboutUs' >
                <hr style={{
                    color: '#1974ff',
                    backgroundColor: '#1974ff',
                    height: .8,
                    borderColor: '#1974ff',
                    width: 60,
                    marginTop: 0,
                    marginLeft: 15
                }} />
                <Text textAlign='left' fontSize={40} mt='5px' ml='15px' fontFamily='SportsWorld' >О компании</Text>
                <Center>
                    <Text color='gray' textAlign='left' fontSize={18} w='100%%' mt='5px' fontFamily='RobotoRegular' >
                        Компания "Фабрика Ворот" начало свою деятельность с 2010 года. Мы предлагаем: Собственное производство; Прямые поставки комплектующих от ведущих и проверенным временем производителей; Контроль качества на всех этапах производства, доставка собственным автопарком.</Text>
                </Center>
                <Accordion w='100%' float='right' mr='3%' mt='250px' >
                    <AccordionItem border='none' >
                        <h2>
                            <AccordionButton>
                                <Box flex='1' textAlign='left' fontSize={20} fontFamily='Cocomat'  >
                                    Ищем новые решения
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4} fontFamily="RobotoRegular">
                            Для улучшения и повышения скорости как производства, так и монтажных работ, не теряя при этом качества. Изготавливаем ворота для любых клиентов, в том числе ворота для парковки, ворота для СТО, автомоек и прочих специфических организаций
                        </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem>
                        <h2>
                            <AccordionButton>
                                <Box flex='1' textAlign='left' fontSize={20} fontFamily='Cocomat'>
                                    Лучшие цены на рынке
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4} fontFamily="RobotoRegular">
                            У нас Вы можете купить ворота в Москве и Московской области по выгодным ценам, так как являемся производителями и делаем минимальные надбавки к стоимости.
                        </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem>
                        <h2>
                            <AccordionButton>
                                <Box flex='1' textAlign='left' fontSize={20} fontFamily='Cocomat'>
                                    Ра<span className='b'>б</span>отаем <span className='b'>б</span>ез предоплаты
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4} fontFamily="RobotoRegular">
                            Мы обеспечиваем превосходный сервис, доверяем нашем клиентам и не берем предоплату, а получаем по факту выполнения, если работа сделана не качественно, Вы не платите, а мы проводим дополнительное обучение сотрудников для исключения таких ситуаций.
                        </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem>
                        <h2>
                            <AccordionButton>
                                <Box flex='1' textAlign='left' fontSize={20} fontFamily='Cocomat'>
                                    Качественные ворота
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4} fontFamily="RobotoRegular">
                            Наши менеджеры контролируют производство на каждом этапе. Качественная сварка конструкции, нуждается в идеальной покраске – поэтому все наши изделия покрываются полимерным покрытием.
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>

                <Image
                    src={require('../../../img/background2.jpeg')}
                    w='90%'
                    h='200px'
                    float='left'
                    mt='20px'
                    ml={window.innerWidth > 1300 ? '100px' : '0px'}
                    id='aboutImg'
                    zIndex={2}
                    pos='absolute' />
            </Box>
        </Center>

    </Box>
}