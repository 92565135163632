import {
    Box,
    Text,
    Button,
    Center
} from "@chakra-ui/react";
import { useEffect } from "react";
import { Link } from "react-scroll";

export default function Description() {

    useEffect(() => {
        const ordercall = document.getElementById("orderCall");
        ordercall.onmouseover = () => ordercall.style.color = '#1C1C1C';
        ordercall.onmouseleave = () => ordercall.style.color = "white";
    }, []);

    return <Box
        w='90%'
        h='300px'
        pos='absolute'
        zIndex={2}
        ml='0'
        mt='210px'
        p={5}
    >
        <Center>
            <Text color='#FFFFFF' textAlign='center' fontSize={34} fontWeight={600} w='110%' ml='30px' mt='-70px' fontFamily='sportsWorld' >Производство и установка</Text>
        </Center>
        <Text color='#FFFFFF' textAlign='justify' fontSize={21} fontWeight={300} mt='20px' ml='10px' w='105%' fontFamily='Cocomat' >Откатных и распашных ворот лю<span className="b">б</span>ой сложности по Москве и Московской о<span className="b">б</span>ласти</Text>

        <Center>
            <Button
                variant='outline'
                colorScheme='gray'
                borderRadius={0}
                color='#FFFFFF'
                w='233px'
                h='65px'
                fontSize={20}
                mt='40px'
                ml='30px'
                border='solid 1px'
                id='orderCall'
            ><Link to='application' smooth={true} >Заказать звонок</Link></Button>
        </Center>
    </Box>
}