import {
    Box,
    Center,
    HStack,
    Text,
    Input,
    Button,
    Icon,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    CloseButton,
    InputLeftAddon,
    InputGroup
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FaTelegram, FaWhatsapp, FaViber } from "react-icons/fa";
import axios from 'axios';


export default function Application() {

    const [name, setName] = useState('');
    const [number, setNumber] = useState('');
    const [message, setMessage] = useState('');

    function sendM(x) {

        x.preventDefault();
        console.log(name, number, message)

        let state = true;

        if (name === '' || number === '' || number.length < 10) {
            document.getElementById('errorMod').style.display = 'block';
            state = false;
        }

        if (state === true) {

            axios.post("https://fabrika-vorot.site:49522/data", {
                firstName: name,
                number: number,
                message: message
            });

            window.ym(90042661, 'reachGoal', 'sending_form_contact')
            console.log('Цель метрики на заявку выполнена');

            const modal = document.getElementById("mod");
            modal.style.display = 'block';
            document.body.style.overflow = 'hidden';
        }
    };

    function close() {
        const modal = document.getElementById("mod");
        modal.style.display = 'none';
        document.body.style.overflow = '';
        document.getElementById("1").value = '';
        document.getElementById("2").value = '';
        document.getElementById("3").value = '';
    }

    function closeError() {
        const modalEr = document.getElementById("errorMod");
        modalEr.style.display = 'none';
        document.body.style.overflow = '';
        document.getElementById("1").value = '';
        document.getElementById("2").value = '';
        document.getElementById("3").value = '';
    }

    useEffect(() => {
        const appliBut = document.getElementById("appliBut");
        const appliText = document.getElementById("appliText");
        appliBut.onmouseover = () => {
            appliBut.style.background = 'black';
            appliText.style.color = 'white';
        }
        appliBut.onmouseleave = () => {
            appliBut.style.background = 'white';
            appliText.style.color = "black";
        }
    }, []);


    return <Box w='100%' h='850px' id='application' >

        <Alert
            status='success'
            variant='subtle'
            flexDirection='column'
            justifyContent='center'
            textAlign='center'
            height='200px'
            style={{ display: 'none' }}
            id='mod'
            pos='fixed'
            bottom='0'
            left='0'
            right='0'
            top='0'
            borderRadius={10}
            w='600px'
            h='180px'
            ml='30%'
            mt='20%'
            zIndex={3}

        >
            <Center>
                <AlertIcon boxSize='40px' mr={0} />
            </Center>
            <AlertTitle mt={4} mb={1} fontSize='lg'>
                Заявка успешно оформлена!
            </AlertTitle>
            <AlertDescription maxWidth='sm'>
                Наш менеджер перезвонит Вам в течению 15 минут. Если все менеджеры заняты, то время ожидания может увеличиться.
            </AlertDescription>
            <CloseButton
                alignSelf='flex-start'
                float='right'
                mr='-15px'
                mt='-115px'
                onClick={close}
            />
        </Alert>

        <Alert
            status='error'
            variant='subtle'
            flexDirection='column'
            justifyContent='center'
            textAlign='center'
            height='200px'
            style={{ display: 'none' }}
            id='errorMod'
            pos='fixed'
            bottom='0'
            left='0'
            right='0'
            top='0'
            borderRadius={10}
            w='600px'
            h='180px'
            ml='30%'
            mt='20%'
            zIndex={3}

        >
            <Center>
                <AlertIcon boxSize='40px' mr={0} />
            </Center>
            <AlertTitle mt={4} mb={1} fontSize='lg'>
                Форма заявки неправильно заполнена!
            </AlertTitle>
            <AlertDescription maxWidth='sm'>
                Проверьте правильно ли вы ввели имя и номер телефона(Номер телефона должен быть без кода страны). Перепроверьте данные и заполните форму снова.
            </AlertDescription>
            <CloseButton
                alignSelf='flex-start'
                float='right'
                mr='-15px'
                mt='-135px'
                onClick={closeError}
            />
        </Alert>

        <Center>
            <Box w='90%' minW='1000px' maxW='1300px' h='850px'  >
                <Center>
                    <hr style={{
                        color: '#1974ff',
                        backgroundColor: '#1974ff',
                        height: .8,
                        borderColor: '#1974ff',
                        width: 60,
                        marginTop: 80,
                    }} />
                </Center>
                <Text textAlign='center' fontSize={36} mt='17px' fontFamily='SportsWorld' >Оформить заявку</Text>
                <Center>
                    <Text color='gray' textAlign='center' fontSize={20} w='700px' mt='5px' fontFamily='RobotoRegular' >
                        Не забудьте указать свой номер телефона, мы Вам перезвоним и договоримся о выезде специалиста. В блоке "Сообщение" Вы можете указать пожелания, требования и все необходимы детали.</Text>
                </Center>
                <Box w='1000px' h='450px' mt='60px' ml='5%' >
                    <HStack>
                        <Box w='700px' h='450px'>
                            <HStack>
                                <Box w='340px' h='60px' >
                                    <Text color='gray' fontSize={19} fontWeight={300} fontFamily='RobotoRegular' >Имя</Text>
                                    <Input
                                        w='335px'
                                        h='55px'
                                        borderRadius={0}
                                        mt='10px'
                                        id='1'
                                        minLength={2}
                                        onChange={x => setName(x.target.value)} />
                                </Box>
                                <Box w='340px' h='60px' >
                                    <Text color='gray' fontSize={19} fontWeight={300} fontFamily='RobotoRegular' >Телефон</Text>
                                    <InputGroup>
                                        <InputLeftAddon children='+7' h='55px' mt='10px' />
                                        <Input
                                            type='tel'
                                            w='335px'
                                            h='55px'
                                            borderRadius={0}
                                            mt='10px'
                                            maxLength={10}
                                            id='2'
                                            placeholder="(987) 654-32-10"
                                            onChange={x => setNumber(x.target.value)} />
                                    </InputGroup>
                                </Box>
                            </HStack>
                            <Box w='680px' h='300px' ml='1px' mt='50px' >
                                <Text color='gray' fontSize={19} fontWeight={300} fontFamily='RobotoRegular' >Сообщение</Text>
                                <Input
                                    w='680px'
                                    h='200px'
                                    borderRadius={0}
                                    mt='10px'
                                    id='3'
                                    onChange={x => setMessage(x.target.value)}
                                />
                            </Box>
                            <Button
                                variant='outline'
                                colorScheme='white'
                                borderRadius={0}
                                background='white'
                                w='200px'
                                h='60px'
                                mt='-50px'
                                ml='3px'
                                border='solid 1px black'
                                id='appliBut'
                                onClick={sendM}
                            ><Text color='black' fontWeight={400} fontSize={18} id='appliText' >Оставить заявку</Text></Button>
                        </Box>
                        <Box w='300px' h='450px'>
                            <Box>
                                <Box w='280px' h='60px' float='right'>
                                    <Text color='gray' fontSize={18} fontWeight={300} fontFamily='RobotoRegular' >Почта</Text>
                                    <a href='mailto:fabrika.vorot@mail.ru'
                                        onClick={() => {
                                            window.ym(90042661, 'reachGoal', 'clicked_on_email')
                                            console.log('Цель метрики на почту');
                                        }} ><Text color='black' className='big' fontSize={18} fontWeight={300} >fabrika.vorot@mail.ru</Text></a>
                                </Box>
                                <Box w='280px' h='60px' float='right' mt='15px'>
                                    <Text color='gray' fontSize={18} fontWeight={300} fontFamily='RobotoRegular' >Телефон</Text>
                                    <Text color='black' fontSize={18} fontWeight={300} className='big' ><a href='tel:+79257096009'
                                        onClick={() => {
                                            window.ym(90042661, 'reachGoal', 'clicked_on_phone')
                                            console.log('Цель метрики на нажатие номера выполнена');
                                        }} >+7 (925) 709-60-09</a></Text>
                                </Box>
                                <Box w='280px' h='60px' float='right' mt='15px'>
                                    <Text color='gray' fontSize={18} fontWeight={300} fontFamily='RobotoRegular' >Мессенджеры</Text>
                                    <HStack ml='0px' mt='7px' >
                                        <a href="tg://resolve?domain=fabrikaVorot"
                                            onClick={() => {
                                                window.ym(90042661, 'reachGoal', 'clicked_on_messenger_telegram')
                                                console.log('Цель метрики на Telegram');
                                            }} className='big' ><Icon as={FaTelegram} w='35px' h='35px' /></a>
                                        <a href="https://wa.me/+79257096009"
                                            onClick={() => {
                                                window.ym(90042661, 'reachGoal', 'clicked_on_messenger_whatsapp')
                                                console.log('Цель метрики на WhatsApp');
                                            }} className='big' ><Icon as={FaWhatsapp} w='35px' h='35px' /></a>
                                        <a href="https://viber.click/79254333444"
                                            onClick={() => {
                                                window.ym(90042661, 'reachGoal', 'clicked_on_messenger_viber')
                                                console.log('Цель метрики на Viber');
                                            }} className='big' ><Icon as={FaViber} w='35px' h='35px' /></a>
                                    </HStack>
                                </Box>
                                <Box w='280px' h='60px' float='right' mt='25px'>
                                    <Text color='gray' fontSize={18} fontWeight={300} fontFamily='RobotoRegular' >Адрес</Text>
                                    <Text color='black' fontSize={18} fontWeight={300} fontFamily='RobotoLight' >город Красногорск, < br />ул. Ильинский тупик, 1, корп. 2</Text>
                                </Box>
                            </Box>
                        </Box>
                    </HStack>
                </Box>
            </Box>
        </Center>

    </Box >
}